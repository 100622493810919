import "./module.css"

import { CalendarOutlined, CloseCircleOutlined, FontSizeOutlined, LoadingOutlined, MailOutlined, MobileOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Input, message, Radio, Select, Space, Spin } from "antd";
import { Loader } from "components";
import { useCreateRegistrationForm } from "features/events/use-cases/create-registration-form";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AddLevelModal } from "../../register-form-modal/add-level-modal";
import { CreateDetailsFormValues } from "./types";

const ItemTypes = {
    BOX: "Box",
    NAME: "Name",
    FNAME: "FName",
    LNAME: "LName",
    TEXT: "text",
    DECISIONBOX: "decisionbox",
    RADIO: "radio",
    TEXTAREA: "textarea",
    DROPDOWN: "dropdown",
    BUTTON: "button",
    FILE: "file",
    DATE: "date",
    EMAIL: 'email',
    PHONE: 'phone',
    CHECKBOX: 'checkbox',
    DOB: 'dob',
};

interface RightContainerProps {
    droppedItems: any[]; // Update this with the appropriate type for dropped items
    setDroppedItems: React.Dispatch<React.SetStateAction<any[]>>;
    userData: any;
}

const FormCreatedComponents: React.FC<RightContainerProps> = ({
    droppedItems,
    setDroppedItems,
    userData
}) => {
    const dragItem = React.useRef<any>(null);
    const dragOverItem = React.useRef<any>(null);
    const { id } = useParams();
    const { TextArea } = Input;

    const [dropLoading, setDropLoading] = useState(false)


    useEffect(() => {
        if (userData?.items.length > 0) {
            const userJsonData = JSON.parse(userData?.items[0]?.registration_json);
            setDroppedItems(userJsonData);
        }
    }, [userData])


    const handleSort = () => {
        const _droppedItems = [...droppedItems];
        const droppedItemContent = _droppedItems.splice(dragItem.current, 1)[0];

        _droppedItems.splice(dragOverItem.current, 0, droppedItemContent);

        // Find the first name associated with the same orderId as the moved last name
        const associatedFirstNameIndex = _droppedItems.findIndex(
            (item) =>
                item.type === "FName" &&
                item.orderID === droppedItemContent.orderID
        );

        if (associatedFirstNameIndex !== -1) {
            // Move the associated first name to match the new position of the last name
            const associatedFirstName = _droppedItems.splice(
                associatedFirstNameIndex,
                1
            )[0];
            _droppedItems.splice(dragOverItem.current, 0, associatedFirstName);
        }

        // Update orderID according to the new order
        let orderIdCounter = 0;
        _droppedItems.forEach((item) => {
            item.orderID = orderIdCounter++;
        });

        dragItem.current = null;
        dragOverItem.current = null;

        setDroppedItems(_droppedItems);
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>(null);
    const [feildId, setFeildId] = useState<number>(0);
    const [boxChildrenId, setBoxChildrenId] = useState<number | undefined>(undefined);

    const [modalLoad, setModalLoad] = useState<boolean>(false);


    const handleFieldClick = (index: number) => {
        //    if (item.labelKey != "email_wa" && item.labelKey != "phone_wa" && item.labelKey != "name"){

        //    }
        setBoxChildrenId(undefined);
        const fieldData = droppedItems[index];
        setModalData({ fieldData });
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
        setFeildId(index);
    };

    const closeModal = () => {
        document.body.style.overflow = 'visible';
        setIsOpen(false);
    };

    const handleSave = (data: any) => {
        setModalLoad(true);
        const index = feildId;
        const childrenIndex = boxChildrenId;
        setDroppedItems((prevDroppedItems) => {
            const updatedDroppedItems = [...prevDroppedItems]; // Copy the array

            if (updatedDroppedItems[index]?.type === "Box" && childrenIndex !== undefined) {
                const updatedBox = { ...updatedDroppedItems[index] }; // Copy the box object
                const updatedChildren = [...updatedBox.childrenFields]; // Copy the children array

                if (childrenIndex !== undefined && updatedChildren[childrenIndex]) {
                    // Replace the child field object keys with the keys and values from data.formData
                    Object.keys(data.formData).forEach(key => {
                        if (key !== "index" && key !== "formData") {
                            updatedChildren[childrenIndex][key] = data.formData[key];
                        }
                    });

                    updatedBox.childrenFields = updatedChildren; // Update the children array in the box object
                    updatedDroppedItems[index] = updatedBox; // Update the box object in the droppedItems array
                }
            } else {
                // Update the specific item at the given index with the new data
                Object.keys(data.formData).forEach(key => {
                    if (key !== "index" && key !== "formData") {
                        updatedDroppedItems[index][key] = data.formData[key];
                    }
                });
            }

            return updatedDroppedItems;
        });
        setTimeout(() => {
            setModalLoad(false);
            closeModal();
        }, 800)

    };





    const handleRemoveField = (index: number) => {
        const newDroppedItems = [...droppedItems];
        const removedItem = newDroppedItems[index];
        if (removedItem.type === "LName") {
            newDroppedItems.splice(index - 1, 2);
            setDroppedItems(newDroppedItems);
        } else {
            newDroppedItems.splice(index, 1);
            setDroppedItems(newDroppedItems);
        }

    };


    const handleRemoveChildField = (event: any, index: number, childIndex: number) => {
        event.stopPropagation();
        const newDroppedItems = [...droppedItems];
        const item = newDroppedItems[index];

        // Check if the item has a children array and if the childIndex is valid
        if (item.childrenFields && childIndex >= 0 && childIndex < item.childrenFields.length) {
            // Remove the child object at the specified index from the children array
            item.childrenFields.splice(childIndex, 1);
            // Update the state with the modified newDroppedItems array
            setDroppedItems(newDroppedItems);
        }
    };


    const handleFieldChildClick = (event: any, index: number, childIndex: number) => {
        event.stopPropagation();
        const fieldData = droppedItems[index];
        setModalData({ fieldData, childIndex });
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
        setFeildId(index);
        setBoxChildrenId(childIndex);
    };


    useEffect(() => {
        renderDroppedItems();
    }, [handleSort])


    const renderChildInput = (child: any) => {
        switch (child.type) {
            case ItemTypes.TEXT:
                return <Input size="large" placeholder={child.placeholder} prefix={<UserOutlined />} />;
            case ItemTypes.EMAIL:
                return <Input size="large" placeholder={child.placeholder} prefix={<UserOutlined />} />;
            case ItemTypes.PHONE:
                return <Input size="large" type={"number"} placeholder={child.placeholder} prefix={<UserOutlined />} />;
            case ItemTypes.TEXTAREA:
                return <TextArea size="large" rows={3} maxLength={6} placeholder={child.placeholder} />;
            case ItemTypes.DROPDOWN:
                return (
                    <Select defaultValue="Select" options={child.options.map((res: any) => ({ label: res }))} onClick={(e) => e.stopPropagation()} />
                );
            case ItemTypes.CHECKBOX:
                return (
                    <Checkbox.Group>
                        {child.options.map((option: string, index: number) => ( // Assuming options is an array of strings
                            <Checkbox key={index} value={option}>{option}</Checkbox>
                        ))}
                    </Checkbox.Group>
                );
            case ItemTypes.DECISIONBOX:
                return (
                    <Checkbox >{child.labelEn}</Checkbox>
                );
            case ItemTypes.RADIO:
                return (
                    <Radio.Group>
                        {child.options.map((option: string, index: number) => ( // Assuming options is an array of strings
                            <Radio key={index} value={option}>{option}</Radio>
                        ))}
                    </Radio.Group>
                );
            case ItemTypes.FILE:
                return (
                    <Card style={{ width: '100%' }}>
                        {/* <Upload> */}
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        {/* </Upload> */}
                    </Card>
                );
            case ItemTypes.DATE:
                return <Input size="large" placeholder={child?.placeholder} prefix={<CalendarOutlined />} />;
            default:
                return null;
        }
    };

    const renderDroppedItems = () => {
        return droppedItems.sort((a, b) => a.orderId - b.orderId).map((item, index) => (

            <div className="erfrm_wrap" key={index}>
                <div
                    key={index}
                    className={`erfsctn  ${(item.type === ItemTypes.NAME) ? "name-group" : ""}${(item.type === ItemTypes.DROPDOWN) ? "agpcgbx" : ""}${(item.type === ItemTypes.CHECKBOX) ? "agpcgbx" : ""}  `
                    }

                    draggable
                    onDragStart={() => (dragItem.current = item.orderID)} // Use orderId for dragItem.current
                    onDragEnter={() => (dragOverItem.current = item.orderID)}
                    onDragEnd={handleSort}
                    onDragOver={(e) => e.preventDefault()}
                    // {...(item.type === ItemTypes.BOX ? { dropzone: "box-space" } : {})}
                    {...(item.type === ItemTypes.BOX ? { "data-dropzone": "box-space" } : {})}
                    style={{ background: (item.type === ItemTypes.BOX ? "#f9f9f9" : "none") }}

                >
                    {(item.type === ItemTypes.BOX) && (
                        <div
                            onClick={() => handleFieldClick(index)}
                            // className="name-fields"
                            style={{ width: "100%" }}
                        >
                            <h3 style={{ margin: "15px" }}>{item.labelEn} </h3>
                            <div

                                // dropzone="box-space"
                                data-dropzone="box-space"
                                style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "5rem", width: "100%", border: "2px dotted gray", backgroundColor: "whitesmoke", margin: "auto" }}
                            >
                                Drop Here for Box
                            </div>
                            <div className="pdd-box pointer">
                                {/* <h3>{item.labelEn} </h3> */}
                                {item.childrenFields && item.childrenFields?.map((child: any, childIndex: number) => (
                                    <div key={childIndex} className="in_bx_fild" onClick={(event) => handleFieldChildClick(event, index, childIndex)}>
                                        <h3>{child?.labelEn}</h3>
                                        <div >
                                            {renderChildInput(child)}
                                        </div>

                                        <CloseCircleOutlined twoToneColor="#dc143c" onClick={(event) => handleRemoveChildField(event, index, childIndex)} />
                                    </div>
                                ))}
                            </div>

                        </div>
                    )}


                    {(item.type === ItemTypes.NAME) && (
                        <div
                            // onClick={() => handleFieldClick(index)}
                            className="nmfld pointer" style={{ cursor: "not-allowed" }}>
                            {item?.childrenFields?.map((child: any, i: number) => (
                                <div key={i} className="slbxdr w-50">
                                    <h3>{child.labelEn} </h3>
                                    <Input size="large" placeholder={child.placeholder} prefix={<UserOutlined />} />
                                </div>
                            ))}
                        </div>
                    )}

                    {(item.type === ItemTypes.DOB) && (
                        <div onClick={() => handleFieldClick(index)} className="nmfld dob_cell pointer">
                            <div>
                                <h3>{item?.labelEn}</h3>
                            </div>
                            <div className="sell_d_wrap">
                                {item?.childrenFields?.map((child: any, i: any) => (
                                    <div className="slbxdr sell_drop" key={i}>
                                        <h3>{child.labelEn}</h3>
                                        {renderChildInput(child)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {item.type === ItemTypes.TEXT && (
                        <>
                            <div onClick={() => handleFieldClick(index)} className="slbxdr pointer">
                                <h3>{item.labelEn} </h3>
                                <Input placeholder={item?.placeholder} prefix={<FontSizeOutlined />} />
                            </div>
                        </>
                    )}
                    {item.type === ItemTypes.EMAIL && (
                        <div className={`slbxdr w-50 pointer ${item.labelKey === "email_wa" ? "not-allowed" : "pointer"}`}
                            onClick={() => item.labelKey !== "email_wa" && handleFieldClick(index)}
                        >
                            <h3>{item.labelEn} </h3>
                            <Input size="large" placeholder={item?.placeholder} prefix={<MailOutlined />} />
                        </div>
                    )}
                    {item.type === ItemTypes.PHONE && (
                        <div className={`slbxdr w-50 ${item.labelKey === "phone_wa" ? "not-allowed" : "pointer"} `}
                            onClick={() => item.labelKey !== "phone_wa" && handleFieldClick(index)}
                        >
                            <h3>{item.labelEn} </h3>
                            <Input size="large" placeholder={item?.placeholder} prefix={<MobileOutlined />} />
                        </div>
                    )}
                    {item.type === ItemTypes.FILE && (
                        <div className="slbxdr file_upld pointer pointer" onClick={() => handleFieldClick(index)}>
                            <h3>{item.labelEn} </h3>
                            <Card style={{ width: '100%' }}>
                                {/* <Upload> */}
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                {/* </Upload> */}
                            </Card>
                        </div>
                    )}
                    {item.type === ItemTypes.DATE && (
                        <div className="slbxdr pointer" onClick={() => handleFieldClick(index)}>
                            <h3>{item.labelEn} </h3>
                            <Input size="large" placeholder={item?.placeholder} prefix={<CalendarOutlined />} />
                        </div>
                    )}
                    {item.type === ItemTypes.DECISIONBOX &&
                        <>
                            <div className="check_bx pointer" onClick={() => handleFieldClick(index)}>
                                <Checkbox >{item.labelEn}</Checkbox>
                            </div>
                            {item?.childrenFields?.map((child: any, i: any) => (
                                <div className="slbxdr sell_drop child_grp" key={i}>
                                    <h3>{child.labelEn}</h3>
                                    {renderChildInput(child)}
                                </div>
                            ))}
                        </>
                    }
                    {item.type === ItemTypes.CHECKBOX &&
                        <>
                            <div className="slbxdr sell_check pointer" onClick={() => handleFieldClick(index)}>
                                <h3>{item.labelEn} </h3>
                                <Checkbox.Group
                                    options={item?.options}
                                />
                            </div>
                            {item?.childrenFields?.map((child: any, i: any) => (
                                <div className="slbxdr sell_drop child_grp" key={i}>
                                    <h3>{child.labelEn}</h3>
                                    {renderChildInput(child)}
                                </div>
                            ))}
                        </>
                    }

                    {item.type === ItemTypes.RADIO &&
                        <div className="pointer" onClick={() => handleFieldClick(index)}>
                            <div className="slbxdr radio_d pointer">
                                <h3>{item.labelEn} </h3>
                                {item.options.length > 0 ?
                                    item.options.map((option: string, i: number) => (
                                        <span key={i}>
                                            <Radio.Group>
                                                <Space direction="vertical">
                                                    <Radio value={"option"}>{option}</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </span>
                                    ))
                                    :
                                    <>
                                        <input type="radio" />
                                        <label htmlFor="radio">Radio Button</label>
                                    </>
                                }
                            </div>
                            {item?.childrenFields?.map((child: any, i: any) => (
                                <div className="slbxdr child_grp" key={i}>
                                    <h3>{child.labelEn}</h3>
                                    {renderChildInput(child)}
                                </div>
                            ))}
                        </div>}

                    {item.type === ItemTypes.TEXTAREA && (
                        <div className="slbxdr pointer" onClick={() => handleFieldClick(index)}>
                            <h3>{item.labelEn}</h3>
                            <TextArea size="large" rows={3} maxLength={6} placeholder={item?.placeholder} />
                        </div>)}


                    {item.type === ItemTypes.DROPDOWN && (
                        <div className="pointer" onClick={() => handleFieldClick(index)}>
                            <div className="slbxdr sell_drop pointer" >
                                <h3>{item.labelEn} </h3>
                                <Select
                                    defaultValue="Select"
                                    onClick={(e) => e.stopPropagation()}
                                    options={item.options?.map((res: any) => ({ label: res }))}
                                />
                            </div>
                            {item?.childrenFields?.map((child: any, i: any) => (
                                <div className="slbxdr sell_radio child_grp" key={i}>
                                    <h3>{child?.labelEn}</h3>
                                    {renderChildInput(child)}
                                </div>
                            ))}
                        </div>
                    )}

                    {item.type === ItemTypes.BUTTON && (
                        <div className="slbxdr pointer" onClick={() => handleFieldClick(index)}>
                            <h3>{item.labelEn}</h3>
                            <button>{item.labelEn ? item.labelEn : "Button"}</button>
                        </div>)}

                    {(item.labelKey != "email_wa" && item.labelKey != "phone_wa" && item.labelKey != "name") && <CloseCircleOutlined twoToneColor="#dc143c" className="close-icon" onClick={() => handleRemoveField(index)} />}
                </div>
            </div>
        ));
    };

    const handleDrop = (event: {
        target: any;
        preventDefault: () => void;
        dataTransfer: { getData: (arg0: string) => string };
    }) => {
        event.preventDefault();
        setDropLoading(true);

        setTimeout(() => {
            setDropLoading(false);
        }, 500);

        const type = event.dataTransfer.getData("type");
        const defaultProperties = JSON.parse(
            event.dataTransfer.getData("defaultProperties")
        );

        const orderID = droppedItems.length;

        if (type === ItemTypes.BOX) {
            // If the dropped item type is BOX, add it to the droppedItems array
            setDroppedItems([
                ...droppedItems,
                {
                    type,
                    ...defaultProperties,
                    orderID,
                    childrenFields: [] // Initialize an empty children array
                }
            ]);
        }
        else if (type === ItemTypes.NAME) {
            // If the dropped item type is NAME, create a parent object with two sub-objects for FName and LName
            const nameObject = {
                type: "Name",
                labelEn: "Name",
                labelAr: "",
                labelKey: "",
                placeholder: "",
                required: false,
                options: [],
                hidden: false,
                orderID: orderID,
                childrenFields: [
                    { type: "text", labelEn: "First Name", labelAr: "", labelKey: "first_name_wa", placeholder: "", required: false, options: [], hidden: false, orderID: 0 },
                    { type: "text", labelEn: "Last Name", labelAr: "", labelKey: "last_name_wa", placeholder: "", required: false, options: [], hidden: false, orderID: 1 }
                ]
            };
            setDroppedItems([...droppedItems, nameObject]);
        }
        else if (type === ItemTypes.DOB) {
            // If the dropped item type is NAME, create a parent object with two sub-objects for FName and LName
            const nameObject = {
                type: "dob",
                labelEn: "Date of Birth",
                labelAr: "",
                labelKey: "date_of_birth_wa",
                placeholder: "",
                required: false,
                options: [],
                hidden: false,
                orderID: orderID,
                childrenFields: [
                    { type: "dropdown", labelEn: "Day", labelAr: "", labelKey: "date_of_birth_wa_day", placeholder: "", required: false, options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], hidden: false, orderID: 0 },
                    { type: "dropdown", labelEn: "Month", labelAr: "", labelKey: "date_of_birth_wa_month", placeholder: "", required: false, options: [" Jan", " Feb", "Mar", "Apr"], hidden: false, orderID: 0 },
                    { type: "dropdown", labelEn: "Year", labelAr: "", labelKey: "date_of_birth_wa_year", placeholder: "", required: false, options: [2010, 2011, 2012, 2013, 2014, 2015, 2016], hidden: false, orderID: 0 },
                ]
            };
            setDroppedItems([...droppedItems, nameObject]);
        }
        else {
            // For other types, check if the drop occurred inside a box
            const dropzone = event.target.getAttribute("data-dropzone");

            if (dropzone === "box-space") {
                // Find the box object in droppedItems and add the dropped item to its children array
                const updatedDroppedItems = droppedItems.map(item => {
                    if (item.type === ItemTypes.BOX) {
                        return {
                            ...item,
                            childrenFields: [
                                ...item.childrenFields,
                                {
                                    type,
                                    ...defaultProperties,
                                    orderID: item.childrenFields.length // Use the length of children array as orderID
                                }
                            ]
                        };
                    }

                    return item;
                });
                setDroppedItems(updatedDroppedItems);
            } else {
                // If not dropped inside a box, add it to the droppedItems array
                setDroppedItems([
                    ...droppedItems,
                    { type, ...defaultProperties, orderID }
                ]);
            }
        }

    };




    const handleDragOver = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
    };

    const { mutate } = useCreateRegistrationForm();
    const [loading, setLoading] = useState(false);

    const handleCreateForm = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const generateLabelKey = (labelEn: string) => {
            return labelEn?.toLowerCase().replace(/\s+/g, '_');
        };

        const updateItem = (item: any) => {
            const updatedItem = { ...item };

            // Generate labelKey for the item if it doesn't exist
            if (!updatedItem.labelKey) {
                updatedItem.labelKey = generateLabelKey(updatedItem.labelEn);
            }

            // Check and update childrenFields if they exist
            if (Array.isArray(updatedItem.childrenFields)) {
                updatedItem.childrenFields = updatedItem.childrenFields.map((child: { labelKey: any; labelEn: string; }) => {
                    if (!child.labelKey) {
                        return {
                            ...child,
                            labelKey: generateLabelKey(child.labelEn)
                        };
                    } else {
                        return child;
                    }
                });
            }

            return updatedItem;
        };

        const updatedItems = droppedItems.map(updateItem);




        // const updatedItems = droppedItems.map((item) => {
        //     // Generate labelKey based on labelEn property if it doesn't exist
        //     if (!item.labelKey) {
        //         const labelKey = item?.labelEn?.toLowerCase().replace(/\s+/g, '_');
        //         return {
        //             ...item,
        //             labelKey: labelKey
        //         };
        //     } else {
        //         return item; // If labelKey already exists, return the item unchanged
        //     }
        // });

        const labelEnValues = droppedItems.map((item) => item.labelEn.toLowerCase());
        const duplicates: string[] = [];
        labelEnValues.forEach((label, index) => {
            if (labelEnValues.indexOf(label) !== index && !duplicates.includes(label)) {
                duplicates.push(label);
            }
        });

        if (duplicates.length > 0) {
            duplicates.forEach((label) => {
                message.error(`Duplicate label ${label} is not allowed.`);
            });

            return;
        }

        const jsonData = JSON.stringify(updatedItems);
        setLoading(true);
        const formValues: CreateDetailsFormValues = {
            id: userData?.items[0]?.id,
            event_id: id,
            registration_json: jsonData,
        }
        const payload = formValues;

        mutate(payload, {
            onSuccess() {
                setLoading(false);
            },
        });
    };


    if (loading) return <Loader />;

    return (

        <>


            <div className='sell'>



                {dropLoading &&

                    <div className="loader_bx">
                        <span>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: "#EE3124" }} spin />} />
                            <b style={{ marginLeft: "2px", color: "#EE3124" }}> Adding Feilds</b>
                        </span>

                    </div>

                }
                <div className='head'>
                </div>

                <div className='erffrm' onDrop={handleDrop} onDragOver={handleDragOver} style={{ minHeight: "80Vh" }}>
                    {droppedItems.length > 0 ? (
                        <>{renderDroppedItems()}</>
                    ) : (
                        <div className="frm_msg">
                            <h2 className='drag-heading'>
                                Please Drag items from Custom Fields and Drop here
                            </h2></div>
                    )}
                </div>

                {droppedItems.length > 0 && <div className='form-button'>
                    <button className="btn" onClick={handleCreateForm} style={{ marginLeft: 20, marginTop: 20 }}>Create Registration Form</button>
                </div>}
            </div>


            <div className={`cstmrightmdl ${isOpen && "show"}`}>
                <AddLevelModal onClose={closeModal} onSubmit={handleSave} isOpen={isOpen} modalData={modalData} modalLoad={modalLoad} />
            </div>

        </>
    );
};

export default FormCreatedComponents;
