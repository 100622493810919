import { Layout, Result404 } from 'components';
import { ResultError } from 'components/errors';
import { MainFiltersProvider } from 'components/main-filters/main-filters.context';
import { sideBarPermission } from 'components/side-bar/side-bar-permission';
import { AccountSettingsView } from 'features/account-settings';
import { LoginView } from 'features/auth';
import { ChooseTshirtSizeView, ColorManagementView, CurrencyView, DiscoverBannerView, EventMasterView, FaqView, GlobalDisclaimerView, ManageAreaView, ManageCountyriesView, RacekitView, SizeManagementView, SportsCategoryView } from 'features/cms';
import { CurrencyConversionView } from 'features/cms/routes/currency-conversion';
import { ManagePartnersView } from 'features/cms/routes/manage-partners';
import DashboardView from 'features/dashboard/routes/dashboard-view';
import { EventDetailsView, EventsView, RequestedEvent } from 'features/events';
import { NotifictionViewList } from 'features/notification/routes/list-view';
import { NotifictionViewTypeList } from 'features/notification/routes/type-view';
import { CategoryListView } from 'features/shop-item/routes/category-list';
import { CouponListView } from 'features/shop-item/routes/coupon-list';
import { DeliveryListView } from 'features/shop-item/routes/delivery-charge-list';
import { ItemListEditView } from 'features/shop-item/routes/item-edit';
import { ItemListView } from 'features/shop-item/routes/item-list';
import DetailPage from 'features/transaction_list/modal/participante-edit';
import { ExpoBoothView } from 'features/transaction_list/routes/expo-booth';
import { InvoiceListView } from 'features/transaction_list/routes/invoice-list';
import { InvoiceDetailsView } from 'features/transaction_list/routes/invoice-list-view';
import { ItemSalesView } from 'features/transaction_list/routes/item-sales';
import ItemSalesDetails from 'features/transaction_list/routes/Item-sales-details';
import { ParticipateListView } from 'features/transaction_list/routes/paritipate-list';
import { ReportsListView } from 'features/transaction_list/routes/reports';
import { UploadListView } from 'features/transaction_list/routes/upload-list';
import UploadListDetails from 'features/transaction_list/routes/upload-list-details';
import { WaitingListView } from 'features/transaction_list/routes/waiting-list';
import { WaitingListDetailsView } from 'features/transaction_list/routes/waiting-list-detail';
import { WalletListView } from 'features/transaction_list/routes/wallet-list';
import {
  AdminDetailView,
  AdminUsersView,
  AdminUserUpdateView,
  AgentDetailView,
  AgentUsersView,
  AgentUserUpdateView,
} from 'features/users';
import { EndUserUpdateView } from 'features/users/routes/end-user-update';
import { EndUsersView } from 'features/users/routes/end-users';
import { UserLogsView } from 'features/users/routes/user-logs';
import { VolunteerUsersView } from 'features/users/routes/volunteer-user';
import {
  VendingMachineEvent,
  VendingMachineReport
} from 'features/vending-machine';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute } from './authenticated-route';
import { ROUTES } from './constants';
import { RestrictedSidebarRoute } from './restricted-route';
import { UnauthenticatedRoute } from './unauthenticated-route';





export const routerConfig: RouteObject[] = [
  {
    errorElement: <ResultError />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <UnauthenticatedRoute>
            <LoginView />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: ROUTES.HOME,
            element: <Navigate to={ROUTES.EVENTS} replace />,
          },
          {
            path: ROUTES.ACCOUNT_SETTINGS,
            element: <AccountSettingsView />,
          },
          {
            path: ROUTES.USERS,
            element: <Navigate to={ROUTES.USERS_APP} replace />,
          },
          {
            path: ROUTES.USERS_APP,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <EndUsersView />
              // </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_APP_EDIT,
                element: (
                  // <RestrictedRoute
                  //   redirectPath={ROUTES.HOME}
                  //   requiredPermission='users.edit'
                  // >
                  <EndUserUpdateView />
                  // </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.DASHBOARD,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <DashboardView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.USERS_ADMIN,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='adminUsers.view'
              // >
              <AdminUsersView />
              // </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_ADMIN_EDIT,
                element: (
                  // <RestrictedRoute
                  //   redirectPath={ROUTES.HOME}
                  //   requiredPermission='adminUsers.edit'
                  // >
                  <AdminUserUpdateView />
                  // </RestrictedRoute>
                ),
              },
            ],
          },


          {
            path: ROUTES.USER_MANAGEMENT_LOGS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='adminUsers.view'
              // >
              <UserLogsView />
              // </RestrictedRoute>
            )
          },

          {
            path: ROUTES.USERS_AGENT_DETAIL,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='adminUsers.view'
              // >
              <AgentDetailView />
              // </RestrictedRoute>
            )
          },
          {
            path: ROUTES.USERS_ADMIN_DETAIL,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='adminUsers.view'
              // >
              <AdminDetailView />
              // </RestrictedRoute>
            )
          },
          {
            path: ROUTES.USERS_AGENT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='adminUsers.view'
              // >
              <AgentUsersView />
              // </RestrictedRoute>

            ),
            children: [
              {
                path: ROUTES.USERS_AGENT_EDIT,
                element: (
                  // <RestrictedRoute
                  //   redirectPath={ROUTES.HOME}
                  //   requiredPermission='adminUsers.edit'
                  // >
                  <AgentUserUpdateView />
                  // </RestrictedRoute>
                ),
              }
            ],

          },


          {
            path: ROUTES.EVENTS,
            element: (
              <EventsView />
            ),
          },
          {
            path: ROUTES.SHOP_ITEM_CATEGORY,
            element: (
              <RestrictedSidebarRoute
                redirectPath={ROUTES.DASHBOARD}
                requiredPermission={sideBarPermission.category}
              >
                <CategoryListView />
              </RestrictedSidebarRoute>
            ),

          },

          {
            path: ROUTES.SHOP_ITEM_ITEM_LIST,
            element: (
              <RestrictedSidebarRoute
                redirectPath={ROUTES.DASHBOARD}
                requiredPermission={sideBarPermission.itemList}
              >
                <ItemListView />
              </RestrictedSidebarRoute>
            ),
          },
          {
            path: ROUTES.COUPON_ITEM,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <CouponListView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SHOP_ITEM_ITEM_LIST_EDIT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <ItemListEditView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SHOP_ITEM_DELIVERY_CHARGES,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <DeliveryListView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.EVENTS_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <EventDetailsView />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.EVENTS_REQUESTED_EVENT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.EVENTS_REQUESTED_EVENT}
              //   requiredPermission='fields.view'
              // >
              <RequestedEvent />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_DISCOVER_BANNER,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <DiscoverBannerView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_SPORTS_CATEGORY,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <SportsCategoryView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_CURRENCY,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <CurrencyView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_CURRENCY_CONVERSION,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <CurrencyConversionView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },



          {
            path: ROUTES.CMS_MANAGE_PARTNERS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ManagePartnersView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_GLOBAL_DISCLAIMER,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <GlobalDisclaimerView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.CMS_MANAGE_COUNTRIES,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ManageCountyriesView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_MANAGE_AREA,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ManageAreaView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.CMS_MANAGE_T_SHIRT_SIZE,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ChooseTshirtSizeView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.CMS_EVENT_MASTER,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <EventMasterView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.CMS_RACE_KIT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <RacekitView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.NOTIFICATION_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <NotifictionViewList />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.NOTIFICATION_TYPE,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <NotifictionViewTypeList />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.VENDING_MACHINE_REPORT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <VendingMachineReport />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          }, {
            path: ROUTES.VENDING_MACHINE_EVENT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <VendingMachineEvent />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.VOLUNTEER_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <VolunteerUsersView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.SHOP_ITEM_COLOR_MANAGEMENT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ColorManagementView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },


          {
            path: ROUTES.SHOP_ITEM_SIZE,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <SizeManagementView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SHOP_ITEM_FAQ,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <FaqView />
              </MainFiltersProvider>
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.TRANSACTION__PARTICIPATE_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ParticipateListView />
              </MainFiltersProvider>
              // </RestrictedRoute>

            ),
          },
          {
            path: ROUTES.TRANSACTION_INVOICE_LIST,
            element: (
              // // <RestrictedRoute
              // //   redirectPath={ROUTES.HOME}
              // //   requiredPermission='users.view'
              // // >
              <InvoiceListView />
              // // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.TRANSACTION_WALLET_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <WalletListView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.TRANSACTION_INVOICE_LIST_EDIT,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <InvoiceDetailsView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.TRANSACTION_WAITING_LIST_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <WaitingListDetailsView />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.TRANSACTION__BULK_UPLOAD_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <UploadListView />
              </MainFiltersProvider>
              // </RestrictedRoute>

            ),
          },
          {
            path: ROUTES.TRANSACTION__BULK_UPLOAD_LIST_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <UploadListDetails />
              </MainFiltersProvider>
              // </RestrictedRoute>

            ),
          },

          {
            path: ROUTES.TRANSACTION__REPORTS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='fields.view'
              // >
              <MainFiltersProvider>
                <ReportsListView />
              </MainFiltersProvider>
              // </RestrictedRoute>

            ),
          },




          {
            path: ROUTES.TRANSACTION_PARTICIPATE_DETAIL,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <DetailPage />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.TRANSACTION__ITEM_SALES,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <ItemSalesView />
              // </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.TRANSACTION__ITEM_SALES_DETAILS,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <ItemSalesDetails />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.TRANSACTION__WAITING_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <WaitingListView />
              // </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.TRANSACTION__EXPO_BOOTH_LIST,
            element: (
              // <RestrictedRoute
              //   redirectPath={ROUTES.HOME}
              //   requiredPermission='users.view'
              // >
              <ExpoBoothView />
              // </RestrictedRoute>
            ),
          },
        ],
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Result404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
