import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Skeleton, Space, Switch, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import {
    StyledTableHeader,
    StyledTableWrapper,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useStatusResponse } from 'features/shop-item/use-cases/update-status-response-msg';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';
import { Item } from 'types/item';


type UsersTableProps = {
    isSocialListLoading: boolean;
    data: any;
    itemsCount: number;
    tableParams: EndUserFilterFormValuesPaginated;
    updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};
const { confirm } = Modal;

export const ItemListTable = ({
    data,
    isSocialListLoading,
    tableParams,
    updateParams,
}: UsersTableProps) => {
    const { t } = useTranslation();
    const permissions = usePermissions();
    const navigate = useNavigate();

    const { mutate: mutateUpdateItem, isLoading: isLoadingUpdateSocial } =
        useStatusResponse();

    if (!permissions) return null;

    const handleDelete = async (id: any) => {

        mutateUpdateItem({
            id: id,
            flag: "SHOP_ITEMS_DELETE",
            status: true
        });
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        sorter: SorterResult<User> | SorterResult<User>[],
    ) => {
        updateParams({
            ...tableParams,
            page: pagination.current,
            size: pagination.pageSize,
            order_by: Array.isArray(sorter)
                ? undefined
                : sorter.order === 'ascend'
                    ? 'name'
                    : '-name',
        });
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: 'Are you sure you want to delete this Item?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(record.id,);
            },
        });
    };

    return (
        <StyledTableWrapper>
            <StyledTableHeader>
                {/* <RecordCountBadge text={t('social.foundSocial')} count={itemsCount} /> */}
            </StyledTableHeader>
            <Table<Item>

                locale={{
                    emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
                }}
                loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
                dataSource={isSocialListLoading ? [] : data?.items}
                // dataSource={data?.items as any}
                rowKey='id'
                onChange={handleTableChange}
                pagination={{
                    current: tableParams?.page,
                    defaultPageSize: tableParams?.size,
                    total: data?.size
                }}
                className='cstm_table cstltblwthldr'
                // scroll={{ x: 1440 }}
                onRow={(record: any) => {
                    return {
                        onClick: () => {
                            navigate(getRoute(ROUTES.SHOP_ITEM_ITEM_LIST_EDIT, record.id));
                        }
                    }
                }}
            >
                <Column<Item>
                    title={t('shops.item.table.itemname')}
                    dataIndex={'title'}
                    render={(_, record: any) => record.title}
                // sorter={true}
                // sortDirections={['ascend', 'descend', 'ascend']}
                // defaultSortOrder={'descend'}
                />
                <Column<Item>
                    title={t('shops.item.table.category')}
                    dataIndex={'shop_category_name'}

                />
                <Column
                    title={t('shops.category.table.action')}
                    render={(_, record: any) => (
                        <Space>
                            <Tooltip title="edit">
                                <Button shape="circle" onClick={() =>
                                    navigate(getRoute(ROUTES.SHOP_ITEM_ITEM_LIST_EDIT, record.id))
                                } icon={<EditOutlined />} />
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button
                                    shape="circle"
                                    loading={isLoadingUpdateSocial}
                                    onClick={() => showDeleteConfirm(record)}
                                    icon={<DeleteOutlined />}
                                />
                            </Tooltip>
                            <Tooltip title="Is Active">
                                <Switch
                                    defaultChecked={record.is_active}
                                    checked={record.is_active}
                                    loading={isLoadingUpdateSocial}
                                    onChange={(isChecked) => {
                                        mutateUpdateItem({
                                            id: record.id,
                                            flag: "SHOP_ITEMS_SET_STATUS",
                                            status: isChecked,
                                        });

                                        record.is_active = isChecked;
                                    }}
                                    onClick={(checked, event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                />
                            </Tooltip>
                        </Space>
                    )}
                />
            </Table>
        </StyledTableWrapper>
    );
};
