import { Button, DatePicker, Form, Grid, Input, message, Select } from 'antd';
import { ContentFilters } from 'components';
import { TransectionApiServices } from 'features/transaction_list/api/transection-api.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { payFormatDate } from 'utils/payload-date-';

import { InvoiceFilterFormField, InvoiceFilterFormValues } from './types';
const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
    formId: string;
    handleFilter: (values: InvoiceFilterFormValues) => void;
    initialValues: InvoiceFilterFormValues;
    closeModal?: () => void;
};

export const InvoiceFilterForm = ({
    formId,
    handleFilter,
    initialValues,
    closeModal,
}: UsersFilterFormProps) => {
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<InvoiceFilterFormValues>();
    const { t } = useTranslation();
    const screens = useBreakpoint();
    const onReset = () => {
        form.resetFields();
        handleFilter({ ...initialValues });
    };

    const onFinish = (formValues: InvoiceFilterFormValues) => {
        const formattedValues = {
            ...formValues,
            [InvoiceFilterFormField.BookingDate]: formValues?.bookingDate && payFormatDate(formValues?.bookingDate),
        };
        handleFilter(formattedValues);
        closeModal?.();
    };


    const [allYear, setAllYear] = useState({
        items: []
    })


    const getYear = async () => {
        try {
            const apires = await TransectionApiServices().getDropDownList(`Flag=${'YEAR'}`)
            setAllYear(apires as any)
        } catch (error) {
            message.error('')
        }
    }
    useEffect(() => {
        getYear();
    }, [])

    const reverseData = [...allYear.items].reverse()

    return (

        <Form<InvoiceFilterFormValues>
            id={formId}
            name={formId}
            className='filters-form'
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={initialValues}
            onFinishFailed={() => {
                setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
        >
            <ContentFilters.FormItemsWrapper>

                <div className='invoice_list'>
                    <Form.Item
                        label={'Year'}
                        name={InvoiceFilterFormField.Year}
                    >
                        <Select
                            // defaultValue="2024"
                            options={[
                                { label: 'All', value: '' }, // Add the "All" option
                                ...reverseData.map((res: any) => ({ label: res.Text, value: res.Id })),
                            ]}
                        />
                    </Form.Item>

                    <Form.Item

                        label={'Invoice Number'}
                        name={InvoiceFilterFormField.InvoiceId}

                    >
                        <Input placeholder={'Enter Invoice Number'} />
                    </Form.Item>
                    <Form.Item
                        label={'Name'}
                        name={InvoiceFilterFormField.Name}
                    >
                        <Input placeholder={'Enter Name'} />
                    </Form.Item>

                    <Form.Item
                        label={'Mobile'}
                        name={InvoiceFilterFormField.MobileNo}
                    >
                        <Input placeholder={'Enter Mobile No'} />
                    </Form.Item>


                    <Form.Item
                        label={'EMAIL'}
                        name={InvoiceFilterFormField.Email}
                    >
                        <Input placeholder={'Enter Email'} />
                    </Form.Item>

                    <Form.Item
                        label={'Pay Type'}
                        name={InvoiceFilterFormField.paymentMethod}
                    >
                        <Select
                            placeholder="Select Pay Type"

                            options={[
                                { value: 'Cash', label: 'Cash' },
                                { value: 'Card', label: 'Card' },
                                { value: 'Wallet', label: 'Wallet' },
                                { value: 'Bank Transfer', label: 'Bank Transfer' },
                                { value: 'Knet', label: 'Knet' },
                                { value: 'AMEX', label: 'AMEX' },
                                { value: 'Mada', label: 'Mada' },
                                { value: 'Apple Pay', label: 'Apple Pay' },
                                { value: 'Omani', label: 'Omani' },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Transaction Status'}
                        name={InvoiceFilterFormField.TransactionStatus}
                    >
                        <Select
                            placeholder="Select Transaction Status"
                            style={{ width: 180 }}
                            options={[
                                { value: 'Captured', label: 'Captured' },
                                { value: 'uncaptured', label: 'Uncaptured' },
                                { value: 'reserved', label: 'Reserved' },
                                { value: 'failed', label: 'Failed' },
                                { value: 'error', label: 'Error' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label={'Booking Date'}
                        name={InvoiceFilterFormField.BookingDate}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label={'Select user type'}
                        name={InvoiceFilterFormField.UserType}
                    >
                        <Select
                            placeholder="Select user type"
                            options={[
                                { value: 'admin', label: 'Admin' },
                                { value: 'appuser', label: 'App User' },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label={'Select Event/  Item Type'}
                        name={InvoiceFilterFormField.EventItem}
                    >
                        <Select
                            placeholder='Select Event/  Item Type'

                            options={[
                                { value: 'Event', label: 'Event' },
                                { value: 'Item', label: 'Item' },
                                { value: 'Both', label: 'Both' },

                            ]}
                        />
                    </Form.Item>


                    <Form.Item
                        label={'Auth Id'}
                        name={InvoiceFilterFormField.AuthId}
                    >
                        <Input placeholder={'Auth Id'} />
                    </Form.Item>

                    <Form.Item
                        label={'Transaction Id'}
                        name={InvoiceFilterFormField.TransactionId}
                    >
                        <Input placeholder={'Enter Transaction Id'} />
                    </Form.Item>


                    <Form.Item
                        label={'Reference no'}
                        name={InvoiceFilterFormField.RefrenceNumber}
                    >
                        <Input placeholder={'Enter Reference No'} />
                    </Form.Item>
                    <Form.Item
                        label={'Payments Currency'}
                        name={InvoiceFilterFormField.Currency}
                    >
                        <Select
                            placeholder="Select Payments Currency"

                            options={[
                                { value: 'KD', label: 'KD' },
                                { value: 'SAR', label: 'SAR' },
                                { value: 'OMR', label: 'OMR' },
                                { value: 'USD', label: 'USD' },
                                { value: 'QAR ', label: 'QAR ' },
                            ]}
                        />
                    </Form.Item>

                    {screens.lg && (
                        <ContentFilters.ButtonsWrapper className='filter_btn'>
                            <Button type='primary' htmlType='submit'>
                                {t('common.buttons.filter', { count: 1 })}
                            </Button>
                            <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
                        </ContentFilters.ButtonsWrapper>
                    )}

                </div>

            </ContentFilters.FormItemsWrapper>

        </Form>
    );
};
