import { usePaginationParams } from 'hooks';

import { ItemListTable } from '../components/item/list-table';
import { ShopItemFormFieldValue } from '../components/shop-filter-form/type';
import { ItemListTopBar } from '../components/top-bar/item-top-bar';
import { useGetItemList } from '../use-cases/get-item';

const getInitialFilterValues: ShopItemFormFieldValue = {
  shop_category_id: undefined,
  is_active: undefined,
  name: undefined
};

export const ItemListView = () => {
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    order_by: 'id',
    ...getInitialFilterValues,
  });

  const { data, refetch, isFetching } = useGetItemList(params);

  const handleFiltersChange = (values: any) => {
    updateParams(values);
    resetPage();
    refetch()
  };


  return (

    <>
      <ItemListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
        refetch={refetch}
      />

      <ItemListTable
        isSocialListLoading={isFetching}
        data={data}
        itemsCount={'' as any}
        tableParams={params}
        updateParams={updateParams}
      // refetch={refetch}
      />
      {/* <Outlet /> */}
    </>
  );
};
