import { CloseOutlined, DownloadOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, message, Select, Tooltip } from 'antd';
import { PageLoader } from 'components/page-loader/page-loader';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TransectionApiServices } from '../api/transection-api.service';
import { useUpdateParticipant } from '../use-cases/update-participate';

interface DetailFieldProps {
    label: string;
    value: string | string[];
    editing: boolean;
    customer_id: any;
}
const DetailPage = () => {
    // const location = useLocation();
    const { id } = useParams();
    const formName = 'add-organizer-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm();
    const [editing, setEditing] = useState<boolean>(false);
    const { isLoading, mutate } = useUpdateParticipant();
    const [participateDetails, setparticipateDetails] = useState({
        participant_addition_details: []
    } as any)

    const { Option } = Select


    // const searh = location?.search
    // const queryParams = searh?.substring(1)?.split('/?');
    // let eventId: any = null;
    // let deviceId: any = null;
    // let indexId: any = null;
    // queryParams.forEach(param => {
    //     const [key, value] = param.split('=');
    //     if (key === 'event_id') {
    //         eventId = value;
    //     } else if (key === 'device_id') {
    //         deviceId = value;
    //     } else if (key === 'index_id') {
    //         indexId = value;
    //     }
    // });

    const handleUpdate = (formData: any) => {
        delete formData.photo_price;
        delete formData.refund_protect_price
        delete formData.reference_no
        delete formData.auth_id
        delete formData.transaction_id
        delete formData.ticket_category_price
        delete formData.tshirt_price

        const participantData = Object.keys(formData)
            .filter(key => key.startsWith('Delivery Address-'))
            .map(key => {
                const customerId = key.split('-')[1]; // Extract customer_id from the key

                return {
                    id: customerId,
                    value: formData[key]
                };
            });


        Object.keys(formData)
            .filter(key => key.startsWith('Delivery Address-'))
            .forEach(key => delete formData[key]);

        const data: any = {
            id: id,
            first_name: formData.first_name,
            last_name: formData.last_name,
            email_address: formData.email_address,
            phone_no: formData.phone_no,
            participant_data: JSON.stringify(participantData) // Convert formData to JSON string
        };


        mutate(data as any, { onSuccess: handleEditClose });
    }

    const handleEditClose = () => {
        setEditing(false);
    }

    const [tabLoading, setTabLoading] = useState(false)

    const initialparticipant = async () => {
        setTabLoading(true);
        try {
            const apiRes = await TransectionApiServices().getParticiPantdetails(`participant_id=${id}`)
            setparticipateDetails(apiRes)
            setTabLoading(false)
            form.setFieldsValue(apiRes)
        } catch (error) {
            message.error("This is wrong")
        }
    }
    useEffect(() => {
        initialparticipant()
    }, [])



    return (

        <>
            {tabLoading ?
                <PageLoader />
                :


                <div>
                    <Form
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        // initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        onFinishFailed={() => setValidateTrigger(['onChange'])}
                        validateTrigger={validateTrigger}
                    >
                        <div className='parti_dtls'>
                            <div className='hed_ttl'>
                                <Breadcrumb separator="/" className='page_brdcrumb'>
                                    <Breadcrumb.Item className='text-decoration-none'><a href='/transactions/participate-List'>Participate List</a></Breadcrumb.Item>
                                    <Breadcrumb.Item className='text-decoration-none'>Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <FormSectionsSeparator title="">
                                <div className='button-group'>
                                    {editing ? (
                                        <>
                                            <Button onClick={() => setEditing(false)} style={{ marginRight: '10px' }}><CloseOutlined /> Cancel</Button>
                                            <Button loading={isLoading} htmlType='submit' className='btn' type='primary'><SaveOutlined /> Save</Button>
                                        </>
                                    ) : (
                                        <Button onClick={() => setEditing(true)} type='primary'><EditOutlined /> Edit </Button>
                                    )}
                                </div>
                            </FormSectionsSeparator>
                        </div>

                        <div className='partic_wrapp invoice_list'>
                            <Form.Item
                                name="event_name"
                                label="Event Name"
                                rules={[{ required: true, message: 'Please Enter your Event Name!' }]}
                            >
                                <Input disabled placeholder="Please Enter Event Name" />
                            </Form.Item>
                            <Form.Item
                                name="first_name"
                                label="First Name"
                                rules={[{ required: true, message: 'Please Enter your First Name!' }]}
                            >
                                <Input disabled={editing === false} placeholder="Please Enter your First Name" />
                            </Form.Item>
                            <Form.Item
                                name="last_name"
                                label="Last Name"
                                rules={[{ required: true, message: 'Please Enter your Last Name!' }]}
                            >
                                <Input disabled={editing === false} placeholder="Please Enter your Last Name" />
                            </Form.Item>
                            <Form.Item
                                name="email_address"
                                label="Email Address"
                                rules={[{ required: true, message: 'Please Enter your Email Address' }]}
                            >
                                <Input disabled={editing === false} placeholder="Please Enter your Email" />
                            </Form.Item>
                            <Form.Item
                                name="phone_no"
                                label="Phone No"
                                rules={[{ required: true, message: 'Please Enter your Phone No!' }]}
                            >
                                <Input disabled={editing === false} placeholder="Please Enter your Phone No" />
                            </Form.Item>
                            {/* <Form.Item
                        name="is_keep_refunded"
                        label="Refund Protected"
                        rules={[{ required: true, message: 'Please input your Phone No!' }]}
                    >
                        <Radio.Group >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item> */}
                            <Form.Item
                                name="is_keep_refunded"
                                label="Refund Protected"
                                rules={[{ required: true, message: 'Please select an option!' }]}
                            >
                                <Select placeholder="Select an option"
                                    disabled
                                >
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="refund_protect_price"
                                label="Refund Protect (in KD)"
                            >
                                <Input disabled placeholder='Enter Refund Protected Price' />
                            </Form.Item>

                            <Form.Item
                                name="medal_price"
                                label="Medal Price"
                                rules={[{ required: true, message: 'Please enter your Medal Price!' }]}
                            >
                                <Input disabled placeholder='Please enter Medal Price' />
                            </Form.Item>
                            <Form.Item
                                name="delivery_price"
                                label="Delivery Price"
                                rules={[{ required: true, message: 'Please enter your Delivery Price!' }]}
                            >
                                <Input disabled placeholder='Please enter your Delivery Price' />
                            </Form.Item>
                            <Form.Item
                                name="currency_name"
                                label="Currency Name"
                            >
                                <Input disabled placeholder='Please enter Currency Name' />
                            </Form.Item>
                            <Form.Item
                                name="payment_method"
                                label="Payment method"
                            >
                                <Input disabled placeholder="Please enter Payment method" />
                            </Form.Item>
                            <Form.Item
                                name="payment_status"
                                label="Payment Status"
                            >
                                <Input disabled placeholder="Please enter Payment Status" />
                            </Form.Item>
                            <Form.Item
                                name="photo_price"
                                label="Photo Price"
                                rules={[{ required: true, message: 'Please enter Photo Price!' }]}
                            >
                                <Input disabled placeholder="Please enter Photo Price" />
                            </Form.Item>
                            <Form.Item
                                name="ticket_category_name"
                                label="Ticket  Name"
                            >
                                <Input disabled placeholder="Please enter Ticket name" />
                            </Form.Item>
                            <Form.Item
                                name="ticket_category_price"
                                label="Ticket  Price"
                                rules={[{ required: true, message: 'Please enter Ticket  Price' }]}
                            >
                                <Input disabled placeholder="ticket_category_price" />
                            </Form.Item>
                            <Form.Item
                                name="tshirt_price"
                                label="Tshirt Price"
                                rules={[{ required: true, message: 'Please enter T-shirt Price' }]}
                            >
                                <Input disabled placeholder="tshirt_price" />
                            </Form.Item>
                            <Form.Item
                                name="transaction_id"
                                label="Transaction Id"
                            >
                                <Input disabled placeholder="Please enter Transaction Id" />
                            </Form.Item>
                            <Form.Item
                                name="auth_id"
                                label="Auth Id"
                            >
                                <Input disabled placeholder="Please enter AuthId" />
                            </Form.Item>
                            <Form.Item
                                name="reference_no"
                                label="Reference No"
                            >
                                <Input disabled placeholder="Please enter Reference No" />
                            </Form.Item>
                        </div>
                        <div className='prti_form'>
                            <FormSectionsSeparator title="">
                                {participateDetails?.participant_addition_details.map((res: any, index: number) => {
                                    return (
                                        <DetailField key={index} label={res.key_lable} value={res?.key_value} editing={editing} customer_id={res.customer_id} />
                                    )
                                })}
                            </FormSectionsSeparator>
                        </div>
                    </Form>
                </div>}
        </>

    );
};
// const DetailField: React.FC<DetailFieldProps> = ({ label, value, editing, customer_id }) => (
//     <Form.Item className='w-100' label={label} name={`${label}-${customer_id}`}>
//         {editing ? (
//             <Input defaultValue={value} disabled />

//         ) : (
//             <div className='in_value vale_name'><span>{value}</span></div>
//         )}
//     </Form.Item>

// );

const handleDownloadImg = (img: any) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const form = document.createElement('form');
    form.method = 'GET';
    form.action = img;

    iframe.appendChild(form);
    form.submit();

    iframe.onload = () => {
        document.body.removeChild(iframe);
        message.success('Image downloaded');
    };

}

const DetailField: React.FC<DetailFieldProps> = ({ label, value, customer_id }) => (
    <Form.Item className='w-100' label={
        <Tooltip title={label} className=''>
            <span className='truncate-label'>{label} </span>
            <span className='dwnld_icon'>{value.includes("https://suffixevents.blob.core.windows.net/events/CustomerEventRegistrationForm/image") && <DownloadOutlined onClick={() => handleDownloadImg(value)} />}</span>
        </Tooltip>
    } name={`${label}-${customer_id}`}>
        {/* {editing ? ( */}
        <Input defaultValue={value} disabled />
        {/* ) : (
            <div className='in_value vale_name'><span>{value}</span></div>
        )} */}
    </Form.Item>
);

export default DetailPage;
