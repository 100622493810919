import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { InvoiceFilterFormValuesPaginated } from '../component/invoice-filter-form/types';
import { InvoicetList } from '../component/invoice-list/invoice-list';
import { InvoiceListTopBar } from '../component/top-bar/invoice-bar';
import { useGetInvoiceList } from '../use-cases/get-invoice-list';

const currentYear = new Date().getFullYear();

const getInitialFilterValues: any = {
  invoice_number: undefined,
  name: undefined,
  MobileNumber: undefined,
  email: undefined,
  paymentMethod: undefined,
  tansactionStatus: undefined,
  bookingDate: undefined,
  tranactionId: undefined,
  RefrenceNumber: undefined,
  AuthId: undefined,
  currency: undefined,
  userType: undefined,
  year: currentYear,
  event_item: undefined
};

export const InvoiceListView = () => {

  const [history, setHistory] = useState("Normal")

  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    ...getInitialFilterValues,
  });

  const { data, refetch, isFetching } = useGetInvoiceList(history, params, { retry: false });

  const handleFiltersChange = (values: InvoiceFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
    refetch()
  };

  useEffect(() => {
    refetch();
  }, [history])

  return (

    <>
      <InvoiceListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
        history={history}
        setHistory={setHistory}
        refetch={refetch}
      />

      <InvoicetList

        isSocialListLoading={isFetching}
        DeliveryList={data}
        tableParams={params as any}
        updateParams={updateParams}
        refetch={refetch}
        isFetching={isFetching}

      />
      <Outlet />
    </>
  );
};
