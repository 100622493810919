import GraphikArabicMedium from 'assets/fonts/graphik-arabic-medium.woff2';
import GraphikArabicRegular from 'assets/fonts/graphik-arabic-regular.woff2';
import GraphikMedium from 'assets/fonts/graphik-medium.woff2';
import GraphikRegular from 'assets/fonts/graphik-regular.woff2';
import patten_1 from 'assets/patten_01.png';
import patten_2 from 'assets/patten_02.png';
import { dropdownMenu } from 'components/dropdown/dropdown.styles';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Graphik', 'GraphikArabic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: baseline;
  }

  /* setting font size to 10px for easier rem calculations */
  html { font-size: 62.5%; };

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .ant-typography strong {
    font-weight: 500;
  }
  .ant-layout-content{color:rgba(0, 0, 0, 0.88);}

  


  .prflbx{
        img{
          max-width: 125px;
        }
  }
 
  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    src: url(${GraphikRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 500;
    src: url(${GraphikMedium}) format('woff2');
  }

  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 400;
    src: url(${GraphikArabicRegular}) format('woff2');
  }


  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 500;
    src: url(${GraphikArabicMedium}) format('woff2');
  }

  .ant-picker-dropdown {
    .ant-picker-today-btn {
      color: ${({ theme }) => theme.colors.greenLight};

      &:hover {
        color: ${({ theme }) => theme.colors.greenDark};
      }
    }
  }

.loader {
  border: 3px solid #f3f3f3; border-top: 3px solid #00473a; border-radius: 50%; width: 20px; height: 20px; position: absolute; right: 13px; bottom: 11px;
  -webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite;  
}
@keyframes spin {
  0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }
}
 
  ${dropdownMenu};

  .input-prefix-icon {
    color: ${({ theme }) => theme.antd.colorIcon};
    margin-right: 8px;
  };







  /* Start Suffix Design Css 20-03-2024 */

  .logo{
    svg{
      g{
        fill:#fff;
      }      
    }
  }

  .cstm_head{
    margin-bottom: 10px;
    
    // .tpmrng{
    //   background: #9CA2A7 !important;
    //   &:hover{
    //     background: #858585 !important; color: #fff !important;
    //   }
    // }

    .hed_ttl{
      .hht_data{
        .title{
          margin:0px; font-size:16px; line-height: 32px;
          span{
            &:nth-child(1){ cursor: pointer; }
            &:nth-child(2){}
            &:nth-child(3){}
            & + span{margin-left:7px;}
            // &:last-child{font-weight:normal;text-transform: capitalize;}
            &.sub_m{font-weight:normal;}
          }  
        }        
      }
    }
  }

  
  .cstm_filterbx{
    .cf_frm{
      .cf_frm_row{
        .ant-form-item{
          .ant-row{
            .ant-col{
              label{
                font-size: 13px; font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .d-none{display:none}

  .bgcard{background:#fff; padding:15px; border-radius:5px; position:relative;}



  

  .sctnbx{ 
    
    
    .sctabbx{ 
      background:#fff; padding:15px 0; border-radius:5px; height:100%;

      /* Main Tab Css Start */
      .ant-tabs-nav{
        .ant-tabs-nav-wrap{
          border-bottom: 1px solid #e7e7e7; padding:0px 0px 3px 0px;

          .ant-tabs-nav-list{
            margin:0 15px !important;
            .ant-tabs-tab{  
              padding:0px; margin:0px;
              
              & + .ant-tabs-tab{margin-left:15px;}
                                    
              .ant-tabs-tab-btn{ font-size:14px; padding:0px 3px 5px 3px;}
              
              &.ant-tabs-tab-active{
                .ant-tabs-tab-btn{ color:#060606; text-shadow:none; }
              }    
              &:nth-last-child(2){border-right:none;}
            }
            .ant-tabs-ink-bar{ height: 3px; }
    
          }
        }
      }
      /* Main Tab Css End */

      
      /* Clid css Start */
      .ant-tabs-tabpane.cltb{

        /* Sub tab css Start */ 
        .gallery_tab {
               
          .ant-tabs-nav{
            padding: 0; margin:0px 0px 10px 0px;
      
            .ant-tabs-nav-wrap{
              border-bottom:0px;              
              .ant-tabs-nav-list{
                background: #FBFBFB; border-radius: 5px; margin: 0px !important; padding: 3px 8px; border:1px solid #f3f3f3;
                .ant-tabs-tab{
                  padding: 0px;
                  .ant-tabs-tab-btn{ 
                    padding: 5px 10px; display: inline-block; border-radius: 5px;
                  }
                  &.ant-tabs-tab-active{
                    .ant-tabs-tab-btn{
                      background:#FFEFEE; color:#EE3124;
                    }
                  }
                  & + .ant-tabs-tab{margin:0px;}
                }
                
              }      
              .ant-tabs-ink-bar{ display: none; }            
            }
          }
          .cltb{
            padding:0;
          }
        }


        /* Sub tab css end */ 

      }



      /* Sub Clid css End */

    


    
      
      .cltb{
        padding:15px;
        .mglrybx{
          .cstm_head{
          button{margin-top: -50px;}
        }
        }
        .cstmfrmscrion{
          display:flex;
          .sell{
            background: #fff; padding: 10px 0px 10px 0px; border-radius: 8px; position:relative;
           & + .sell{margin-left:15px; padding:right:15px;}
            &.sell:nth-child(1){width:74%;border-right:1px solid #ccc; border-radius:0px;}
            &.sell:nth-child(3){width:26%;padding-left: 5px;position: sticky; top: 0px;
              .rghtfrm{
                position: sticky; top: 55px;
              }
            }
            
            .head{
              margin-bottom: 10px;
              h2{
                font-weight: 500; font-size: 16px;
              }
            }

            .cslist{
              margin-bottom: 10px; padding: 5px; background-color: #F6F6F6; border: 1px solid #DADADA; cursor: pointer; border-radius:5px;    color: #6C757D; width:289px;max-width:100%;
              span.anticon{margin-right:6px;}
            }

            .erffrm{
              
              .erfsctn{
                .slbxdr{
                  .ant-input-affix-wrapper{ border-radius:4px; border:1px solid #C8C8C8;    height: 35px;                
                    input::placeholder{
                      font-size:12px; color:#6C757D;
                    }
                    
                  }
                  textarea{border-radius:4px;}
                  .ant-select{width:100%;

                    .ant-select-selector{border-radius:4px;height:35px;    padding: 3px 10px;}
                  }
                }
              }

              .erfsctn{
                position: relative; padding: 15px; 
                border: 2px solid #fff;
                &:hover{
                  
                 border: 2px dashed #8b8b8b;border-radius: 5px;
                  .close-icon{display:block;}                  
                }                                
                &.agpcgbx {
                  .w-50{
                    width:50%;
                    .ant-select{
                      width:100%;                      
                    }
                  }
                  .slbxdr{
                    & + .slbxdr{margin-top:15px;}
                  }
                  // display:flex;
                }

                .close-icon{
                  position: absolute; right: 7px; top: 7px; display:none;
                  svg{fill:#EE3124}
                }

                .slbxdr {
                  h3{
                    font-size: 14px; font-weight: 400; margin-bottom: 5px; display: block;
                  }
                }
                .nmfld{
                  display: flex;width: 100%;
                  .w-50{width:50%}
                  .slbxdr {
                    & + .slbxdr {margin-left:15px;}
                  }                  
                }

                              
              }
            }
          
        }


          .form-button{
            .btn{
              background: #000 !important;color: #fff !important;font-size: 13px !important;padding: 6px 12px !important;border: none !important;border-radius: 4px !important;
              &:hover{
                background: rgba(0, 0, 0, 0.6) !important;
                color: #fff !important;
              }
            }
          }

        }




        .tabcstmbx{

          .edbx{
            .cellbx{
              .edbxcld{
                
                .ssfrm{

                  .ant-form-item {margin-bottom:0px;}

                  .esrdlst{ 
                    margin:0px 0px 10px 0px;
                    .es_rdlbl{
                      .esrlist{
                        display:flex; margin-bottom:5px;
                      }
                    }
                  }
                   
                  .form-button{
                    .btn{
                      color: #fff; background-color: #000; border-color: #000; border: none; padding: 7px 24px; font-size: 14px; font-weight: 500; line-height: 1.5;
                      border-radius: 5px;
                      &:hover{
                        background: #EE3124; color: #fff;
                      }
                    }
                  }
                }

                .ant-row{
                  & + .ant-row{margin-top:15px;}
                }
                
                
                .edbxcldclm{
                  .ant-form-item{margin:0px;}
                  .media{
                    .btn{}
                  }
                }
                .edfromedtr {
                  // padding-left:15px;
                  .quill{
                    // height:100%;
                    .ql-cstm_check_bx{height: calc(100% - 66px); margin-bottom:15px;}                    
                  }
                }

                .edfrom{
                   margin-left:-10px; margin-right:-10px;
                  margin-top: 10px;
                  .cell_box{
                    width:33.33%; padding:0px 10px; margin-bottom: 15px;
                    .ant-row{
                      .ant-col{
                        padding: 0 0 5px;
                         label{ font-size: 13px; font-weight: 500; }
                         .ant-input-outlined[disabled]{color: #000;}
                         .ant-select-disabled{
                         .ant-select-selection-item{color: #000;}
                         }
                         .ant-picker-disabled{
                         .ant-picker-input{
                         input{color:#000;}
                         }
                         }
                      }
                    }
                  }
                }                
              }
              
            }
          }

        }
      }

    }

  }

  .sctnbx .sctabbx .ant-tabs-nav:before{display:none;}


  .cstmrightmdl{  
    
    &.child_modal{ 
      .modal_overlay{ z-index: 999; }
      .popup { width: 100%; max-width: 550px; 
        .modal_body{
          .mb_cstmbx{
            .form-group.is_req{
              input{width:auto;vertical-align: middle;margin-right: 10px;}
              label{    display: inline-block;margin: 0;}
              input[type="checkbox"]{margin-right:10px; vertical-align: middle;}
            }
          }
        }
      }

    }

    .modal_overlay {
      width: 100vw; height: 100vh; background: #00000042; position: fixed; top: 0px; right: 0px; bottom: 0px; left: 0px; z-index: 10; 
    }
    .popup {      
      position: fixed; top: 0; right: 0; width: 50%; height: 100%; background-color: white; box-shadow: -2px 3px 6px #00000054;
      transition: all 0.3s ease-in-out; z-index: 999; visibility: visible; opacity: 1;
      
      .modal_head {
        padding:10px 15px; background:#fcea47; display: flex; justify-content: space-between; line-height: 28px; height: 46px;
        h4 { color: rgba(0, 0, 0, 0.88); font-size: 16px; font-weight: 500; }
        .close-btn {
          position: absolute; top: 5px; right: 5px; border: none; background: none; cursor: pointer; font-size: 29px; color:rgba(0, 0, 0, 0.88);          
        }
      }

      .modal_body {
        height: calc(100vh - 50px);
        overflow: auto;
        
        .mb_cstmbx{
          max-height: calc(100vh - 125px); overflow: auto;
          .cstm_form_group{
            margin-bottom: 15px; background: #f9f9f9; padding: 10px; border-radius: 5px; border: 1px solid #eee;
            label{ font-size: 14px; font-weight: 500; margin-bottom: 7px; display: block; }
            .form-control{font-size:14px; }  
            
            .mltyckbx{
              position: relative;
              select{
                position: relative;display: inline-block;width: 100%;min-width: 0;background-color: #ffffff;background-image: none;border-width: 1px;border-style: solid;border-color: #d9d9d9;border-radius: 6px;transition: all 0.2s;margin: 0;padding: 4px 9px;color: rgba(0, 0, 0, 0.88);font-size: 14px;height: 32px;
              }
              .ant-radio-group{
                display: flex; flex-wrap: wrap;
                label{
                  border: 1px solid #d9d9d9; padding: 3px 7px; border-radius: 15px; font-size: 13px; display: flex; align-items: center;
                }
              }

              .ant-checkbox-wrapper{
                display: flex; flex-wrap: wrap;
                label{
                  border: 1px solid #d9d9d9; padding: 3px 7px; border-radius: 15px; font-size: 13px; display: flex; align-items: center;
                }
              }

              .ant-checkbox-group{
                display: flex; flex-wrap: wrap;
                label{
                  border: 1px solid #d9d9d9; padding: 3px 7px; border-radius: 15px; font-size: 13px; display: flex; align-items: center;
                }
              }

              .close-icon{ position: absolute; right: 0px; top: -20px; }

            }

          }

          .form-group{
            margin-bottom:15px;
            position:relative;
           
            label{ font-size: 13px; font-weight: 500; margin-bottom: 5px; display: block; }
            .ant-select{width:100%;
              &:after{content: ' ';border: solid #878686;border-width: 0 2px 2px 0;display: inline-block;padding: 3px;transform: rotate(45deg); position: absolute;top: 9px;right: 15px;}
            &.ant-select-show-arrow{
              &:after{display:none;}
            }
            }
            .form-control{font-size:14px;}

                      
            .mlty_bx{
              display:flex; margin-bottom:10px;
              .form-control{margin-right:10px;}
              .btn{}
            }
            .optnbx{
              display: flex; flex-wrap: wrap;
              .optn_cell{
                border: 1px solid #d9d9d9; padding: 3px 7px; border-radius: 15px; font-size: 13px;
                & + .optn_cell{margin-left:7px;}
                &:hover{border-color:#e65b4e; color:#e65b4e;}
                .option-close-icon{}
              }
            }
          }


          .mf_cstm_bx{

            .cld_grp_tbn{
              text-align:right; margin-bottom:5px;
              .btn{
                background: no-repeat; font-size: 12px; color: #ee3124; border: 1px solid #ee3124; border-radius: 3px; padding: 6px 12px; font-weight: 600;
              }
            }

            .group-button{
              .btn{
                background: #000 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 6px 22px !important;
    border: none !important;
    border-radius: 4px !important;
                &:hover{ background: rgba(0, 0, 0, 0.6) !important;
                  color: #fff !important; }
              }
            }


            .modal_bx{
              .form-group{
                margin-bottom:15px;
                label{ font-size: 13px; font-weight: 500; margin-bottom: 5px; display: block; }
                .form-control{font-size:14px;}
                .mlty_bx{
                  display:flex;

                }
              }
            }
          }


        }


        padding: 15px;
        form{
          .ant-form-item{
            .ant-row{
              .ant-col {
                label{
                  font-size:14px;
                }
                .ant-form-item-control-input{
                  .ant-form-item-control-input-content{
                    .ant-input-affix-wrapper {
                      padding: 5px 11px;
                      .ant-input-prefix{}
                      .ant-input{
                        height:auto;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .button-group{
          .btn{
            background: #000 !important;
    color: #fff !important;
    font-size: 14px !important;
    padding: 6px 22px !important;
    border: none !important;
    border-radius: 4px !important;
            &:hover{
              background: rgba(0,0,0,0.6) !important; color: #fff;
            }
          }
        }
      }

    }    
  }











  /* End Suffix Design Css 20-03-2024 */



.edfromedtr {
  h3 { font-size: 16px; font-weight: 500;}
  .descrip{
    display: grid; grid-template-columns: 44% 56%; margin-top: 11px;
    
    .des_img{
      width: 100%; height: 250px; background-color: #f3f3f3; overflow:hidden; position: relative; background-size: cover; background-position: center;
      z-index:0;

      &:after{
        content:''; position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        background: #33333385;
        z-index: -1;
        backdrop-filter: blur(10px);
      }      
      img{ 
        width: auto; height: auto; max-width: 100%; max-height: 100%; margin: auto; position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px;
        object-fit: cover;
      }
    }
    
    .des_dtls{ 
      margin-left: 20px;
      .des_d_head{
        display: flex; justify-content: space-between; margin-bottom: 10px; align-items: center;
        h5{ font-size: 16px; font-weight: 500;}
        button{
          padding: 3px 10px; height: auto; font-size: 13px; font-weight: 500; border-color: #DBDBDB; display: inline-block;
        }
      }
      .des_b{
        
    border: 0px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 220px;
    margin: 0 auto;
    font-size: 14px;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
        p{font-size: 14px; border:0px;}
      }
    }
  }
}

.edbx_clm {


  &.ant-col.cellbx.edbx_clm{
    .bgcard.evt_set{
      position: sticky; top: 60px;
    }
  }




  .evt_set{
    p { border-bottom: 1px solid #e7e7e7; padding-bottom: 6px; font-size: 16px; font-weight:500;}
  }

  .sbx_1 {
		
    .sb_form {
      margin-bottom: 15px; border-bottom: 1px solid #ccc; padding: 0px 0 10px;

			&:last-child { border-bottom: 0px; }
			h4 { margin-bottom: 8px; color: #393939; font-size: 14px; font-weight: 500; }

			.eb_f {
				display: grid; grid-template-columns: 1fr 1fr;

				.ef_l {
					margin-bottom: 7px; label {font-size:13px;}

					span.ant-checkbox {
            &.ant-checkbox-checked{
              .ant-checkbox-inner{border-color:#000!important; background: #000;}
            }
						span.ant-checkbox-inner { border: 1px solid #bdbdbd; border-radius: 0px; }
					}



				}
			}

			.radio_bx {
				.ant-radio-group {
					display: grid;
					grid-template-columns: 1fr 1fr;

					label.ant-radio-wrapper {
						margin-bottom: 10px;


						span.ant-radio {
              &.ant-radio-checked{
                .ant-radio-inner{border-color:#000!important; background:#000;}
              }
							span.ant-radio-inner {border-color: #bdbdbd;}
						}
					}
				}
			}

		}
	}

}



.event_dtls{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.lctn_frm {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  
}

.edfrom p{
  border:none;
  padding:0px;
}



.lctn_dtls {
  padding: 5px 0;
  h4{ margin-left: 10px; margin-bottom: 5px; font-size: 16px; font-weight: 500; color: #2c2c2c; }  
}


.cltb .sctabbx .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active { background: #FFEFEE; padding: 6px 15px; border-radius: 5px; border-right:1px solid #FFEFEE; }
.cltb .sctabbx .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar { display: none; }
.cltb .sctabbx .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab { margin: 8px 10px; padding: 6px 15px;}


.sctabbx .cstm_head button,.btn_common {
  // background: #ee3124;
  background:#000;
    color: #fff;
    font-size: 13px;
    padding:6px 12px;
   // height: auto;
    border: none;
    border-radius: 4px;
}

.sctabbx .cstm_head button:hover,.btn_common:hover{
  // background: #d51104 !important;
  background:  rgba(0, 0, 0, 0.6) !important;
    color: #fff !important;
}



.glry_list {
  display: flex;
  flex-wrap: wrap;
  .glery_bx{
    width: 31%;
    margin-right: 15px;
    margin-bottom: 15px;
    background: #fff;
    max-width:100%;
    box-shadow: 1px 1px 10px #e7e6e6;
    border-radius: 0px 0px 5px 5px;
    .ant-image{
      width: 100%;
      height: 150px;
      overflow: hidden;

      img{
        width: 100%;
    height: 100% !important;
    object-fit: cover;
      }

    }
    .img_edit{
      display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 9px;
    padding: 2px 9px 4px 9px;
    label.ant-checkbox-wrapper{
      font-size: 12px;
    display: flex;
    align-items: center;
    span.ant-checkbox-inner{
      width: 16px;
    height: 16px;
    border-radius: 0;
    }

    }
    .icon_bx{
      background: #FEF6F5;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    span{
      color: #ee3124;
    }
    }


    }

  }
}





.glry_list .glery_bx .ant-image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    /* margin-bottom: 4px; */
}

.glry_list .glery_bx .ant-image img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}












.ssfrm .sbx_1:last-child {
    border: 0;
}






.item_wrap {
  .cstm_head{
    display: flex;
    justify-content: space-between;
     padding: 0px;
    
    align-items: center;
    

    h2{font-size: 15px;font-weight: 500;}
    
    
    
  }

  .item_d{
    margin-top: 10px;
    display: flex;
    
    .item_bx{
      margin-right:10px;    width: 42%;
      &:nth-child(3){margin-right:0px;}
      &.img_b{
        width: 16%;    margin-top: 30px;
        .item_img{
          width: 100%;height: 262px;border: 1px solid #ccc;overflow: hidden;display: flex;justify-content: center;align-items: center;border-radius:7px;
            img{ width: 100%;height: 100%;object-fit: cover;}
        }
      }

      .ant-form-item{margin:0px;
        .ql-editor{height: 217px !important;}
      }
    }

   

  }
  .dtls_form_list{ display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  .dtls_f{
    width: 15.33%;
    max-width: 100%;
    margin-right: 10px;
    .ant-form-item{
      margin-bottom: 15px;
      label{font-size: 13px;font-weight: 500;}
    }
  }
  
  }
  .dtls_btn{display:flex;}
  .ant-form-item{margin:0px;}
  .bg_white{background:#fff; padding:10px; border-radius:7px; margin-bottom:15px;}
  

}



 
 @media(max-width:1600px){
  .item_bx{
    width: 39% !important;
    &.img_b{
      width: 22% !important;
      .item_img{
        height: 264px !important;
        border-radius: 4px;
      }
    }
  }
 }

 @media(max-width:1400px){
  .item_bx{
    width: 39% !important;
    &.img_b{
      width: 22% !important;
      .item_img{
        height: 260px !important;
        border-radius: 4px;
      }
    }
    
  }

 }








// .gallery_tab .cstm_head button {
//   background: #9CA2A7;
//   display: flex;
//   align-items: center;
// }

// .gallery_tab .cstm_head button span.ant-btn-icon {
//   font-size: 15px;
// }
.event_table .ant-table table {
  border: 1px solid #F1F1F1;
  border-radius: 8px;
  display:table;
  // table-layout:auto !important;
}
.event_table .ant-table table thead th {
  border-bottom: 0px;
}





.upld_file_bx {
	border: 1px dashed hwb(208deg 43% 51% / 50%);
	width: 400px;
	margin: auto;
	padding: 20px;
	border-radius: 8px;
	text-align: center;

	span.anticon {
		color: #EE3124;
		font-size: 40px;
		margin-bottom: 10px;
	}

	.upld_file {
		margin: 20px 0;

		label {
			background: #EFEFEF;
			padding: 10px;
			border: 1px solid #C8C8C8;
			border-radius: 4px;
			margin-right: 10px;
			font-weight: 500;
		}

		span {
			color: #292D32;
			font-weight: 500;
			display: block;
      word-break: break-all;
    margin-top: 25px;
		}

	}

	span {
		margin-top: 15px;
		display: block;
	}

	button.save_btn {
		background: #EE3124;
		border: 1px solid #EE3124;
		color: #fff;
		padding: 4px 18px;
		height: auto;
		display: flex;
		justify-content: center;
		margin: auto;
		align-items: center;
		margin-top: 10px;

		span {
			margin: 0;
		}
	}
}









.t_shirt{
  h3{color:#000; font-size:18px; margin-bottom:10px; font-weight:500;}
  .t_slist{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .ts_wrap{
      text-align: left;
    width: 150px;
    margin-right: 20px;
    margin-bottom: 45px;
    height:120px;
    .t_sbx{
      width:100%;
    border: 1px solid #ccc;
    height:100%;
    
    padding: 10px;
    margin-bottom: 5px;
    max-width: 100%;
  img{width:100%; height:100%;}
    
  }
  .t_lbl{
    font-size: 12px;
    label{margin-right:10px;
      span.ant-checkbox{
        span.ant-checkbox-inner{border-color:#bdbdbd;border-radius:0px}
      }
    }
  }
    }
  }

  button{
    
   
  }
}
}


// new style

.add_img_wrap{
  .add_img_head{
    display:flex;
    justify-content:space-between;
    margin-bottom:10px;
    h4{font-size:18px;font-weight:500;}
    button{background: #ee3124;color: #fff;border: none;font-weight: 500;}
  }
  .ad_im_wrap{
    display: flex;
    flex-wrap: wrap;
    .ad_img{
      width: 20.3%;
      margin-right: 20px;
      border: 1px solid #ccc;
      padding: 10px;
      .ime_bx{width:100%;height:200px;overflow:hidden; border-radius:5px;
        img{width:100%; height:100%; object-fit:cover;}
      }

      .img_e{
        display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    button{background: #ee3124;
      color: #fff;
      border: none;
      font-weight: 500;
      .ant-btn-icon{
        font-size: 18px;
    }
    }
      }
    }
  }
}
 
 .size_wrap{
  .sze_head{
    display:flex;
    justify-content:space-between;
    margin-bottom:10px;

    
  }
  .ant-pagination{
    display:none;
  }
 }
 
.rslt_m{
  .rslt_mdl_head{display:flex; justify-content:space-between;align-items:center;
    h5{    font-size: 17px;font-weight: 500;}
  }
  .rslt_mdl_bdy{
    .pdf_list{display:flex; flex-wrap:wrap;margin-top: 30px;
      .pdf_bx{
        margin-right: 50px;
        margin-bottom: 20px;
        img{display:block; margin:auto}
        p{display:block;text-align: center;margin: 5px 0; }
        button{font-size: 13px;
          display: flex;
          align-items: center;
          height: 29px;
          border-radius: 3px;
        span.anticon{
          font-size: 16px;
        }
        }
      }
    }
  }
}

.tket_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // margin-top: -32px;
  align-items: center;
 

  button{

      &:last-child{margin-left:10px;
      }
  }
}

.main_side{
  .side-bar-sider{
    background:#181818;

    .ant-layout-sider-children{
      .ant-menu{
        background: #181818;

        .ant-menu-item{
          background:transparent;
          .ant-menu-title-content{
            a{
              color:#fff;
            }
          }
        }

        .ant-menu-submenu{
          // background: #2d2d2d;
          &:hover{ background: #000; }
          border-bottom: 1px solid #292929; padding-bottom: 5px;
          
          // & + .ant-menu-submenu{margin-top:5px;}

          &.ant-menu-submenu-selected{
            background:#000;
          }

          .ant-menu-submenu-title{            
            .ant-menu-title-content{ color:#fff; }
            i{color:#fff;}
          }
          .ant-menu{
            padding-bottom:10px;
            .ant-menu-item{
              height:auto; padding:12px 0px;
              &:hover{background: #434343;}
              &.ant-menu-item-selected{
                background: #434343;
                .ant-menu-title-content{font-width:600;}
              }
              .ant-menu-title-content{
                line-height: normal; height: auto;

                a{
                  color:#fff; font-size:13px;
                }
              }
            }
          }
        }
      }
    }

    .ant-layout-sider-trigger{ background:#333; }

  }
}

.main_side::-webkit-scrollbar {
  width: 5px;  
}

/* Track */
.main_side::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.main_side::-webkit-scrollbar-thumb {
  background: #EE3124; 
}

/* Handle on hover */
.main_side::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.r_side_btn {
  margin: 0;
}

.sell_d_wrap {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}
.nmfld.dob_cell {
  display: inline-block !important;
} 
.erfsctn .pdd-box .in_bx_fild:hover span.anticon-close-circle{display:block;position: absolute;right: 7px;top: 8px;}
.erfsctn{
  .pdd-box{
    .in_bx_fild{position:relative;margin-top:10px;border:2px solid transparent;padding:10px;border-radius:4px;
      &:hover{border: 2px dashed #8b8b8b;}

      
      span.anticon-close-circle{display:none;
        svg{ fill: #EE3124;}
      }
      .ant-input-affix-wrapper{border-radius: 4px;
        border: 1px solid #C8C8C8;
        height: 35px;
      
      }
      .ant-select{width:100%;}
    }
  }
}



.add_drpdwn_o{
  lable{}
  input{margin-bottom:10px;}
  .option{
    margin-top:15px;
    display:flex;
    flex-wrap:wrap;
    .optn_cell{
      border: 1px solid #ccc;
    padding: 4px 4px 2px 4px;
    margin-right: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    span.anticon.anticon-close-circle{
      margin-left:10px;
    }
    }
  }
}

.slbxdr.child_grp {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.slbxdr.child_grp h3 {
  font-size: 13px !important;
}


.sctnbx .sctabbx .ant-tabs-tabpane.cltb .gallery_tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:hover {
  background: #f8dad8;
}

// .erfsctn .pdd-box .in_bx_fild:hover {
  
// }



.gallery_tab .cstm_head button:hover {
  background: #9ba2a7b0;
}

.upld_file_bx button.save_btn:hover {
  background: #ef7f7a;
  color: #fff !important;
}


.upld_modl {
  .popup{width: 35%;
    .upld_file_bx{margin-top:30px;}
    .imgup_wrap{
      display:flex;flex-wrap:wrap;
    .img_bx{width:100px; height:100px; overflow:hidden; margin-right:10px;
      img{width:100%; height:100%;}
    }
    }
    .upld_btn {
      display: flex;
    justify-content: left;
    margin-top: 20px;
      button{
        background: #fa392f;
    color: #fff;
    border: none;
      }
    }
  }
}



.erfrm_wrap{
  padding:10px 40px;
  border-bottom:1px solid #ccc;
}
.ant-form-item{
  .ant-form-item-control-input{
    .ant-form-item-control-input-content{
      input{
        &:hover{border-color: #a5a0a0;}
      }
      .ant-select{
        &:hover{
          .ant-select-selector{border-color: #a5a0a0;}
        }
      }
      .ant-input-affix-wrapper{
        &:hover{
          border-color: #a5a0a0;
        }
      }
      .ant-picker{
        &:hover{
          border-color: #a5a0a0;
        }
      }
    }
  }
}
// .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-select:hover .ant-select-selector {
  
// }




.row_wrap{
  display: flex;  

  .col{
    position: relative; transition: all 0.5s ease;
    &:nth-child(1){width: 70%; padding-right:15px;}
    &:nth-child(2){width: 30%; transform: translateX(0);}
    .ar_icon {
      position: absolute; top: 5px; right: 25px; 
      .btn{
        background: #ffefef; width: 35px; height: 35px; padding: 0px;     border: none; display: flex; align-items: center; justify-content: center;
        .stngicon{
          animation: rotation infinite 3s linear;
        }
        &:hover { font-size: 20px; }
      }
    }
  }

  &.visible{
    .col{     
      .ar_icon{
        right:10px;
      } 
      &:nth-child(1){padding-right:0px;width: 100%;}
      &:nth-child(2){
        transform: translateX(115%);width: 0px;overflow: hidden;
        .bgcard{padding:0px;}
      }
    }    
  }
}


@keyframes rotation{
  from{
    transform:rotate(0deg);
  }
  
  to{
    transform:rotate(360deg);
  }
}





.sctnbx{
  .sctabbx{
    .cltb.attri{
     
      .regis_form{
        padding-left: 0;
        padding-top: 0;
      }
    }
  }
}

.row_wrap.visible .sctabbx .cltb .cstmfrmscrion .sell .cslist {
  width: 289px;
}


.cstmrightmdl{
 
  &.show{
    .popup{
      transform: translateX(0px);
      transition: 0.4s all ease-in-out;
    }
    &:after{
      content: ''; background: #00000026; position: fixed; left: 0px; right: 0px; top: 0px; bottom: 0px; z-index: 100;
    }
  }
  .popup{
    position: fixed; right: 0px; top: 0px; bottom: 0px; width: 100%; max-width: 550px; z-index: 101; background: #fff;transform: translateX(100%);
    transition: 0.4s all ease-in-out;
    .modal_head{
      background: #fcea47; padding: 5px 15px 6px 15px; height:46px; display: flex; align-items: center; justify-content: space-between;
      h4{ color: rgba(0, 0, 0, 0.88); font-size: 16px; font-weight: 500; }
      .close-btn{ border: none; background: none; cursor: pointer; font-size: 29px; color:rgba(0, 0, 0, 0.88); }
    }
    .modal_body{
      padding:15px;
      .frmscrll{
        max-height: calc(100vh - 135px); overflow: auto; height:auto;
        .form-button{
          border-top: 1px solid #e3e3e3; padding-top: 15px; margin-top: 15px; 
          .btn{}
        }
          .des_editr{margin-bottom: 30px;
          & + .des_editr{margin-bottom:0px;}
          h2{
          font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
          }
          }
      }      
    }
  }
}
}



.t_shirt{
  .t_slist{
    .ts_wrap {
      &.tshrt_size{
        height: 50px !important;
        width: max-content !important;
        min-width: 192px;

    .t_sbx{
      label{margin-right:10px;
        span.ant-checkbox{
          .ant-checkbox-inner{
            border-color: #FFCFCC;
    border-radius: 0px;
          }
        }
      }
    }
      }
    }
  }
}

.rslt_wrap{
  margin:0px; padding:0px;
  .rslt_l{
    margin-top:10px;
    display:flex;
    .result_form{
      padding:0px;position: relative; width: 42%;
      margin-right: 20px;
      .ant-form-item-control-input-content{
        display:flex;
        input{height:40px; border:1px solid #C8C8C8; border-radius:4px;}
      }
    }
    .button-group{
      // margin-left:10px;
      button.ant-btn {
        height: 38px;
        background: #000;
    color: #fff;
    font-size: 16px;
    padding: 6px 22px;
    border: none;
    border-radius: 4px;
    }

  }
}
}
















.adtct{
  margin-right:10px;
}



.loader_bx{
  display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;

    span{
      background:#fff;
      padding:10px;
      .ant-spin{
        span.anticon {
          padding:0px;
        }
      }
    }
  
}


.cstmrightmdl{
  &.child_modal{
    &.show{
      .popup{
        z-index: 105;
      }
      &:after{
        z-index: 104;
      }
    }
  }
}


.prti_form{
  display:flex; flex-wrap:wrap; width:100%;background: #fff;padding: 10px;
  .ant-form-item{
   
    width: 25.3%;
    margin-right: 15px;
    margin-bottom: 15px;
    .ant-col{
      padding:0;
      label{font-size: 14px;margin-bottom: 4px;color:rgba(0, 0, 0, 0.88);}
      .ant-form-item-control-input-content{
        input{
        &.ant-input-outlined[disabled]{ color:#000;}
        }
     .in_value{
      background: #e9e9e9;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      padding: 7px;
      border-radius: 4px;
       height: auto;
       word-wrap: break-word;
       span{}
     }
      }
    }
  }
}
.prti_form{
  .radio_btn{
    .ant-col{
      .ant-form-item-control-input-content{
        background:none;
        border:none;
      }
    }

  }
}

.filter_f{
  .fliter_lst{
    display: flex;
    flex-direction: unset;
    flex-wrap:wrap;
    align-items:end;
    .ant-form-item{
      min-width: 160px;
      max-width: 160px;
      margin-bottom:0px;
      &.filt_btn {
        margin: 0;
        
        
        button{
          &.btn_common{margin-right:10px;}
          &.submit_btn{margin-right:10px;} 
        }
    }
    }
  }
}

.frm_msg{
  height: 70vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h2{    color: #a9a9a9;}
}


.ivce_list_wrap{margin: auto;display: flex;justify-content: center;max-width: 100%;width: 800px;position:relative;
  .ivce_list{width:100%;margin-top: 40px;
    .snd_btn{display: flex;justify-content: end;margin-bottom: 10px;position:absolute; right:0; top:0
    }
    .img_details{height: 200px;overflow: hidden;width: 100%;position: relative;
      img{    width: 100%;height: 100%;object-fit: fill;position: absolute;left: 0;right: 0;top: 0;bottom: 0;}
    }
    .evt_dtls{
      background: #fff;margin-top: -4px;padding: 15px;
      .evt_d_head{display:flex;justify-content:space-between;align-items:center;
        h2{font-size: 18px;}
        span{    font-size: 14px;font-weight: 500;}
      }

      .bokng_dtls{margin-top:15px;
        .bokg_info{display: flex;align-items: center;    margin-bottom: 6px;
          h4{font-weight: 500;margin-right: 10px;min-width: 130px;}
          span{
            &.success{color:green;}
          }
        }
      }

    .particpant_dtls{margin-top:15px;
      table{display:table; width:100%; table-layout:fixed;
        thead{background:#ccc;
          th{padding: 7px 10px;text-align: left;font-size: 13px;font-weight: 500;}
        }
        tbody{
          td{padding: 7px 10px;text-align: left;font-size: 13px;vertical-align: top;
            .event_img{
              .e_img{width: 100px;height: 100px;border: 1px solid #ccc;margin-bottom: 10px;
                img{width:100%; height:100%;}
              }
              h3{font-weight:500;}
              span{display:block; font-size:13px;}
            }
            .parti_nme{
              h5{font-size:13px; font-weight:500;}
              span{display:block;}
            }
            
          }
        }

      }
    }

    .shop_item{margin-top:15px;
      h2{font-size:20px; font-weight:500;}
      .shop_list{margin-top: 10px;
        .shop_lst_bx{ border: 1px solid #ccc;padding: 10px;display: flex;justify-content: space-between;align-items: end;
          .pr_dtls{display:flex;
            .pr_img{width: 70px;height: 70px;border: 1px solid #ccc;margin-right: 10px;
              img{width:100%; height:100%;}
            }
            .pr_nme{
              h4{font-weight:500;}
              span{font-size:13px; margin-right:15px;
                &:nth-child(4){margin-right:0;}
                }
            }
          }
          .pr_price{
            span{font-size:16px; font-weight:500;}
          }
        }
      }
    }


    .delvry_addrs{margin-top:15px;
      h6{font-weight:500;}
      .del_nme{
        margin-top:5px;
        h4{font-size:18px; font-weight: 500;
          span{font-weight: 400;font-size: 14px;margin-left: 10px;}
        }
        p{color:#999; font-size: 13px;}

      }
    }


    .ivoice_lst_wrap{
      margin-top: 20px;
      .invce_list{display: flex;justify-content: end;text-align: right;    padding: 10px 0;    border-bottom: 1px solid #ccc;
        display: grid;
        grid-template-columns: 1fr 110px;
        span{    padding-right: 50px;font-weight: 500; font-size:13px;
          &:nth-child(2){padding:0;}
        }
        h3{padding-right: 50px;font-weight: 500; font-size:18px;
          &:nth-child(2){padding:0;}
        }
       
      }
    }

    .nte{margin-top:10px; text-align:center;
      p{font-size:11px;}
    }

    }
  }
}




.add_tct{
  .add_tct_wrap{
    display:flex;
    .add_tct_col{
      margin-right:10px;
      &:last-child{margin-right:0px;}
    }
  }
}



.no_img {
span {
  font-size: 24px;
  font-weight: 500;
}
}

// item sales design

.itm_sle_wrap{
  background: #fff;
  .itm_sle_dtls{
    
    .sle_dtls_head{
      display:flex;align-items:center;background: #FFEFEE;padding: 6px 10px; justify-content:space-between;
      span{color:#000; margin-right:5px;vertical-align: middle;font-size: 15px;}
      h4{font-size: 18px;color: #000;font-weight: 500;}
      a{color: #fa392f;font-weight: 500;}
      .arr_i{display:flex; align-item:center;}
    }
    .cstmer_dtls{padding:20px 10px;display: flex;flex-wrap: wrap;
      .cstmer_lst{width: 33.33%;display: flex;margin-bottom: 10px;
        span{margin-right: 10px;font-weight: 500; font-size:14px}
        p{font-size:14px;}
        &.addrs_f{width:50%;}
      }
    }
    .item_lst_wrap{padding:20px 10px;
      .item_lst{display: flex;margin-bottom:20px;
        .item_img{
          width: 300px;height: 148px;border: 1px solid #ccc;margin-right: 15px;position: relative;overflow: hidden;
            img{width: auto;height: auto;max-width: 100%;max-height: 100%;margin: auto;position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;object-fit: cover;}
        }
        .item_dtls{
          span{display:block; margin-bottom:5px;}
        }
      }
      .ttl_pr{text-align: right;
        p{font-size:16px; margin-bottom:5px;}
        .gr_ttl{border-top:1px dashed #ccc; padding-top:10px;
          p{font-weight:500; font-size:18px;}
        }
      }
    }
    .order_dtls{padding:20px 10px;
      table{display:table; width:100%;
        thead{background:#ccc;
          th{padding:5px 10px; text-align:left; font-weight:500;}
        }
        tbody{
          tr{
            border-bottom:1px solid #ccc;
            &:last-child{border:none;}
            td{
              padding:10px; text-align:left;
            }
          }
        }
      }
    }
  }
}

.invoice_list{
  display:flex; flex-wrap:wrap; gap:10px;
  .ant-form-item{
    margin-bottom: 10px;
    width: 13%;
    .ant-form-item-control-input{
    .ant-form-item-control-input-content{
    input{
    &.ant-input-outlined[disabled]{
        color: #000;
    }
    }
    }
    }
  }
  .filter_btn{width:auto;}
}

@media(max-width:1600px){
  .invoice_list{
    display:flex; flex-wrap:wrap; gap:10px;
    .ant-form-item{
      margin-bottom: 10px;
      width: 15%
    }
    .filter_btn{width:auto;}
  }
}
@media(max-width:1366px){
  .invoice_list{
    display:flex; flex-wrap:wrap; gap:10px;
    .ant-form-item{
      margin-bottom: 10px;
      width: 19%
    }
    .filter_btn{width:auto;}
  }

 
}

.hd_modal{
  display: flex;
  align-items: center;
  h1{
    margin-left: 15px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }

  button{
    background: #ffefef;
    width: 35px;
    height: 35px;
    padding: 0px;
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}


.not_txt{margin-bottom: 15px;display: flex;align-items: start;justify-content: space-between;
  h3{font-size: 16px;font-weight: 500;margin-bottom: 5px;}
  a{  font-size: 14px;color: #0952b9;margin-top: 6px;display: block;}
  p{ font-size: 14px;color: #000;}
}

.event_bx{
 width: 100%;
 .evt_nme{    display: flex;width: 100%;
  .ant-form-item{
    margin-bottom: 10px;
    width: 28%;
    margin-right: 10px;
  }
 }
  a{font-size: 12px;color: #0952b9;margin-top: 6px;display: block;    margin-left: 83px;
    span{margin-left:5px;}
    &:hover{text-decoration:underline;}
  }
  .ant-form-item{
    
  }
}



// .cstm_filterbx{padding:10px;}


.partic_wrapp{
  margin-bottom: 15px;
  background: #fff;
  padding: 10px;
  
  
  .ant-form-item{ 
    
  }
}


.parti_dtls{
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
  p{margin:0;}
}

.page_brdcrumb{
  ol{
    li{font-size:16px;
      span{
        a{color:rgba(0, 0, 0, 0.88); font-weight:500;}
      }
    }
  }
}
.bulk_upld_wrap{
  background: #fff;
  padding: 16px;
  border-radius: 5px; 
}


.prfle_crd{
  margin-bottom:20px;
  .profile_data{display: flex;justify-content: left;
  .prf_img{margin-right:20px;
    .prf_img_bx{width: 230px;height: 200px;border: 1px solid #ccc;display: flex;justify-content: center;overflow: hidden;position: relative;
      img{width: 100%;height: 100%;max-width: 100%;max-height: 100%;margin: auto;position: absolute;left: 0px;right: 0px;top: 0px;bottom: 0px;object-fit: cover;}
    }
      button.ant-btn{    margin-top: 10px;width: 100%;}
    .upld_file{margin-top: 20px;text-align: center;
      label{background: #EFEFEF;padding: 7px;border: 1px solid #C8C8C8;border-radius: 4px;margin-right: 10px;font-weight: 500;}
      span{color: #292D32;font-weight: 500;word-break: break-all;margin-top: 25px;}
    }
  }
  .user_d{
    width:100%;
    p{font-size:14px;font-weight:500; margin-bottom:10px;
      span{font-weight:400;    margin-left: 7px;}
    }
  }
  }
}

.role_card{
  .role_list{display:grid;  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    label {margin-bottom:10px}
  }
  .role_btn{display:flex; justify-content:end;}
}

@media(max-width:1366px){
  .role_card{
    .role_list{display:grid;  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      
    }
  }
}




.ant-picker-cell-inner{background:#fff !important; color:#000 !important;}
.color_bx{
  width:15px;
  height:15px;
  display:inline-block;
  vertical-align: middle;
}



.arow_tab{display:flex;align-items: center;
  h1{margin-left: 10px;}
}



.pointer{cursor:pointer;}
.not-allowed{cursor:not-allowed;}


.cstm_table{
  table{
    tbody{
      tr{cursor:pointer;}
    }
  }
}


.layout_table{
  table{table-layout: auto !important;}
}


.dash_head{
  margin-bottom: 10px;
  h3{margin: 0px;font-size: 16px;line-height: 32px;color: rgba(0, 0, 0, 0.88);font-weight: 500;}
}
.dash_graph{margin-top:20px;
  background:#fff; padding:15px;
  .dash_gph_head{display:flex; justify-content:space-between;margin-bottom: 10px;
    h4{font-size: 16px;font-weight: 500;}
.filter_dash{    

form{
display: flex;
  margin-bottom: 20px;

  .ant-form-item{    max-width: 160px;width: 160px; margin-left:10px;
    &:first-child{margin-left:0px;}
    &:last-child{max-width: 210px;width: 210px;}
  }
}
}
}
.dash_chart{
  .recharts-wrapper{
    display: flex;
    justify-content: center;
    margin: auto;
    
  }
}

}


.col2_graph{
  margin-top:20px;
  .col2_head{background: #fff;padding: 10px;text-align: center;
    h3{    font-size: 20px;font-weight: 500;}
    
  }
  .dash_row{margin-top:10px;display: flex;
    .dash_box{background: #fff;
      padding: 15px;
      width: 50%;
      &:first-child{margin-right:10px;}
      .dash_gph_head{display:flex; justify-content:space-between;margin-bottom: 10px;
        h4{font-size: 16px;font-weight: 500;}
    .filter_dash{    

      form{display: flex;
      margin-bottom: 20px;
      .ant-form-item{    max-width: 100px;width: 100px; margin-left:10px;
        &:first-child{margin-left:0px;}
      }
}
    }
    }
    }
  }
}



.col3_graph{
  margin-top:20px;
  .col2_head{background: #fff;padding: 10px;text-align: center;
    h3{    font-size: 20px;font-weight: 500;}
    
  }
  .dash_row{margin-top:10px;display: flex;
    .dash_box{background: #fff;
      padding: 15px;
      width: 33.3%;
      margin-left:10px;
      &:first-child{margin-left:0px;}
      .dash_gph_head{display:flex; justify-content:space-between;margin-bottom: 10px;
        h4{font-size: 16px;font-weight: 500;}
    .filter_dash{    
      form{display: flex;
      margin-bottom: 20px;
      .ant-form-item{    max-width: 100px;width: 100px; margin-left:10px;
        &:first-child{margin-left:0px;}
      }
}
    }
    }
    }
  }
}



.ttl_list_b{text-align: right;background: #fff;padding: 10px;
  .ttl_list{padding: 10px 0;border-bottom: 1px solid #ccc;display: flex;justify-content: end;
    &:last-child{border:0px;}
    span{
      font-size:14px;font-weight:400;

      & + span{    width: 100px; font-weight:500;}
    }
  }
}
.cupn_code{margin-top: 10px;border: 1px solid #ccc;width: 460px;display: flex;margin-left: auto;padding: 10px;max-width: 100%;background: #f3f3f3;align-items:center;
  h4{margin-right:10px; color:green;}
  input{margin-right: 10px;border: 1px solid #ccc;border-radius: 3px; height:30px;}
  button{background: #000;color: #fff;padding: 4px 8px;border: 1px solid #000;font-size: 14px;border-radius: 3px;min-width:90px;}

}
.pymnt_dlts {
  margin-top: 15px;
  .row{    display: flex;
    flex-wrap: wrap;
    .col-8{width:60%;    padding-right: 15px;
      .pay_box{    background: #fff;padding: 10px;font-size: 14px;
        p{
          span{color: #fa392f;margin-right: 6px;}
        }
        .actn_wrap{margin-top: 10px;
          .acnt_dtls{display:flex;    margin-bottom: 5px;
            span{    color: #c4c5c7;margin-right: 10px;width: 120px;
              & + span{    color: #000;width: auto;margin-right: 0;}
            }
          }
        }
        .ref_no{display:flex; justify-content:space-between;    margin-top: 10px;align-items: center;
          input{padding: 7px;border: 1px solid #ccc;}
        }
      }
    }
    .col-4{width:40%;
      .pm_m{
        h1{font-weight:500; margin-bottom:15px;}
        
      }
    }
  }
}
.trms_cndn {
  margin-bottom: 20px;
}


.cstm_r {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.cstm_r input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


.cstm_checkmark {
  position: absolute;
  top: 5px;
  left: 0px;
  height: 15px;
  width: 15px;
  /* background-color: #eee; */
  border-radius: 50%;
  border: 1px solid #000;

}





.cstm_r input:checked ~ .cstm_checkmark {
  background-color: #000;
}


.cstm_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.cstm_r input:checked ~ .cstm_checkmark:after {
  display: block;
}


.cstm_r .cstm_checkmark:after {
  top: 3px;
    left: 3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;

}


.cstm_check_bx {
  display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.cstm_check_bx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.check_checkmark {
  position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border: 1px solid #000;
    border-radius: 2px
}




.cstm_check_bx input:checked ~ .check_checkmark {
  background-color: #000;
}


.check_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}


.cstm_check_bx input:checked ~ .check_checkmark:after {
  display: block;
}


.cstm_check_bx .check_checkmark:after {
  left: 4px;
    top: 2px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.two_btn{
  display:flex;
}


.sctn_login{
  background:#fcea47; height:100vh; position: relative;
  &:after{
    content:''; background-image: url(${patten_1});
    width: 200px;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 200px;
    background-size: cover;
  }
  &:before{
    content:'';
    background-image: url(${patten_2});
    width: 200px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 200px;
    background-size: cover;
  }
  .lgn_logo{
    text-align: center; margin-bottom: 35px;
    img{ width: 100%; margin: auto; max-width:400px;}
  }
  .sl_bx{
    max-width: 450px; margin: auto; width:100%;    
    .slb_rw{
      .slbr_clm{
        .ant-form{
          .ant-form-item{
            .ant-row{
              .ant-col{
                label{font-size: 14px; font-weight: 500; color: #575757;}
                .ant-form-item-control-input{}
              }
            }
          }
          .ant-btn.btn{
            background: #000; color: #fff; font-size: 14px; font-weight: 500; padding: 7px 0px; height: auto; border-radius: 5px;
          }
        }
      }
    }
  }  
}


.ant-btn-primary{
  Background:#333;
  &:hover{
    background:#000 !important;
  }
}

.newloader{
  .nlbx{
    display: flex; align-items: flex-end;
    .call{
      img{ width: 125px; }
      svg{ width: 30px; position: relative; top: 11px; }
      & + .call{margin-left:7px;}
    }
  }
}


.cstltblwthldr{
  .ant-spin-nested-loading{


    .ant-spin{
    display: flex; align-items: center; justify-content: center; left: 0px; right: 0px; top: 0px; bottom: 0px; position: absolute; z-index: 1;
      .ant-spin-dot{
        top:unset;
        .ant-space{
        width: 100%; height: 100%;        
          .ant-space-item{
            .cstmtblldr{
              .nlbx{
                display: flex; align-items: flex-end;
                .call{
                  img{ width: 125px; }
                  svg{ width: 30px; position: relative; top: 11px; }
                  & + .call{margin-left:7px;}
                }
              }
            }            
          }
        }
      }
    }
  }
}



td.ant-table-cell.invoice_bld:hover {
    font-weight: 500;
}


.truncate-label {
    display: inline-block;
    max-width: 150px; /* Adjust this value as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}



.scrl_tble{
.ant-table{
.ant-table-container{
.ant-table-content{
    overflow: auto;
    min-height: 300px;
}
}
}
}

.early-bird-row{
background-color:rgb(171 171 171 / 88%);
}



.edit_info_btns{
    display: flex;
    justify-content: end;

    button.ant-btn{
    margin-right: 10px;
     &.cancel_btn{border: 1px solid #333;background: #fff;color: #333;
    &:hover{color:#fff;}
    }
    & + button{
    margin-right:0;
    }
   
    }
}


span.dwnld_icon {
    margin-left: 10px;
    span.anticon{
    font-size: 18px;
    color: #000;
    }
}




.pge_load{width:100%; height:100%;
.ant-space-item{width:100%; height:100%;
.cstmtblldr{width:100%; height:100%;    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
 .nlbx{
                display: flex; align-items: flex-end;
                .call{
                  img{ width: 125px; }
                  svg{ width: 30px; position: relative; top: 11px; }
                  & + .call{margin-left:7px;}
                }
              }

}
}
}


.role_card {
.role_list_agent{display: grid;grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
.agnt_nme{margin-bottom:10px;display: flex;align-items: center;
.anticon {margin-right: 8px;
svg{width:18px; height:18px;color: #fa392f;}
}
}
}
}


.main_frm{display: grid;width: 100%;grid-template-columns: 22% 1fr;border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
.ant-form-item{margin-right:20px;
& + .ant-form-item{margin-right:0;}



button{margin-top:30px;}
}
}

.brder_btn{
// background:#333;
color:#333;
border:1px solid #333;
font-weight:500;
}



.col3_graph .dash_row .dash_box .dash_chart .recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
}



.ant-select-item-option-content {
    // display: flex;
    // justify-content: center;
    .ant-spin{display:flex; justify-content:center;}
}
    .error{
    color:red !important;
    }




    .waiting_list{
    padding:15px;
    form{
    .frm_dtls_w{ display:flex; flex-wrap:wrap;
    .ant-form-item{
    width:60%;
    margin-right:20px;
}
    }
    }
    }

    .paygreen{
    color:green
    }
     .payred{
    color:green
    }





    .terms_condn{
    h3{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: block;
    }
    textarea{cursor:pointer;}
    label{ margin-top:20px;
    .ant-checkbox{align-self: flex-start !important;}

    span{
    .check_bx_label{
    display: block;
    display: -webkit-box;
    /* max-width: 100%; */
    height: 103px;
    font-size: 14px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    }
    }
    
    }

    .registr_btn{margin-top:20px;}


a.smpe_exl {
    color: #000;
    font-weight: 500;
}

a.smpe_exl span.anticon.anticon-download {
    margin-right: 6px;
}
    .error{
    color:red;
    }


.parti_btn {
    display: flex;
    align-items: center;
    
 button{
 margin-right: 10px;
 & + button{
 margin:0;
 }
 
 }

}


.wait_btn {
    display: flex;
    justify-content: end;
  
    button{
     margin-right: 10px;

     & + button{
     margin:0;
     }
    }

}


.gnrte_rprt {
    text-align: center;
    color:grey;
        font-weight: 500;
}

.gnrte_rprt h2 {
    font-size:20px;
    font-weight: 500;
    margin-bottom: 6px;
}
.gnrte_rprt h3{
font-size:15px;}



.gnrte_rprt h3 a {
    color: #cb2a1f;
    font-weight: 500;
    text-decoration: underline;
}
    
.loading {
  font-weight: bold;
  display:inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 2s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}


.no_wrap{    white-space: nowrap;}

.invce_bx{margin-left:10px;}


.cupn_code input.form-control.invalid {
    border: 1px solid red;
}

.rmve_cupn_btn {
    background-color: #ea0202 !important;
    border: 1px solid #ea0202 !important;
}


.rprt_filter {
    justify-content: start;
}
    .dwmld_btn {
    font-size: 11px;
    background: green;
    padding: 3px 6px;
    height: 25px;
    color: #fff;
}

.dwmld_btn:hover {
    background: #0080006b !important;
}  

.prepare_btn{
font-size: 11px;
    
    padding: 3px 6px;
    height: 25px;
    color: #fff;
}

.itm_sle_wrap .itm_sle_dtls .item_lst_wrap .item_lst .item_dtls span.clr_bx {
    display: flex;
}

.itm_sle_wrap .itm_sle_dtls .item_lst_wrap .item_lst .item_dtls span.clr_bx span{margin-left:10px;}


`;
