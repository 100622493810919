import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { ContentFilters, ContentTopBar } from 'components';
import { useState } from 'react';
import { CompanyType } from 'types';

import { ParticipateFilterForm } from '../transaction-filter-form/participate-list-filter';
import { ParticiateFilterFormValues } from '../transaction-filter-form/type';
// import { ParticipantReportModal } from './participant-report-filter/participant-report-modal';
type ParticipateTopBarProps = {
  getInitialFilterValues: ParticiateFilterFormValues;
  handleFilter: (values: ParticiateFilterFormValues) => void;
};
export const ExpoBoothTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: ParticipateTopBarProps) => {
  useState<CompanyType>();
  const formId = 'participate-filters-form';
  const [participant, setParticipant] = useState<boolean>(false);

  const openParticipantModal = () => {
    setParticipant(true)
  }

  // const closeParticipantModal = () => {
  //   setParticipant(false)
  // }


  return (

    <>
      <div className={`cstmrightmdl ${participant && "show"}`}>
        {/* <ParticipantReportModal
          closeModal={closeParticipantModal} isOpen={participant}
        /> */}
      </div>

      <ContentTopBar
        title={'Expo Booth list'}
        renderButtons={
          <Tooltip title={"Export"}>
            <Button
              icon={<FileExcelOutlined />}
              onClick={openParticipantModal}
              type='primary'
            // loading={loading}
            >
              Expo Booth
            </Button>
          </Tooltip>

        }
      />
      <ContentFilters formId={formId}>
        <ParticipateFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
