import './module.css';
import '../../../../components/styles/rightModal.css'

import { CalendarOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Card, Checkbox, Input, message, Radio, Select, Upload } from 'antd';
import { EventsApiService } from 'features/events/api/events-api.service';
import { useEffect, useState } from 'react';

import { CurrencyCategory } from '../modals/types';
import ChildrenFeildsModal from './children-feilds-modal';

interface AddLevelModalProps {
    onClose: () => void;
    onSubmit: (data: any) => void;
    isOpen: boolean,
    modalData: any,
    modalLoad: boolean
}

export const AddLevelModal = ({ onClose, onSubmit, isOpen, modalData, modalLoad }: AddLevelModalProps) => {
    const [newOption, setNewOption] = useState("");
    const [editIndex, setEditIndex] = useState<any | undefined>(undefined);
    const [formData, setFormData] = useState<any>({
        labelEn: "",
        labelAr: "",
        placeholder: "",
        required: "",
        hidden: false,
        options: [],
        childrenFields: [],
    });

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';

            if (modalData?.childIndex !== undefined) {
                // If a childIndex is provided, populate the form with the data of the child field at that index
                const childField = modalData.fieldData?.childrenFields[modalData.childIndex];
                if (childField) {
                    setFormData({
                        labelEn: childField.labelEn,
                        labelAr: childField.labelAr,
                        placeholder: childField.placeholder,
                        required: childField.required,
                        options: childField.options,
                        hidden: childField.hidden,
                        type: childField.type,
                        childrenFields: childField.childrenFields || []
                    });
                }
            } else if (modalData?.fieldData?.type !== "Name") {
                // If no childIndex is provided or the type is not "Name", populate the form with the parent field data
                setFormData({
                    labelEn: modalData.fieldData?.labelEn,
                    labelAr: modalData.fieldData?.labelAr,
                    placeholder: modalData.fieldData?.placeholder,
                    required: modalData.fieldData?.required,
                    options: modalData.fieldData?.options,
                    hidden: modalData.fieldData?.hidden,
                    type: modalData.fieldData?.type,
                    childrenFields: modalData.fieldData?.childrenFields || []
                });
            }
        }
    }, [isOpen, modalData, modalData?.childIndex]);


    const [nameData, setNameData] = useState<any>(() => {
        if (modalData?.fieldData?.type === "Name" && modalData?.fieldData?.childrenFields) {
            return {
                labelEn: modalData?.fieldData?.labelEn,
                labelAr: "sd",
                placeholder: "",
                required: modalData?.fieldData?.required,
                options: [],
                childrenFields: modalData.fieldData.childrenFields.map((child: any, index: number) => ({
                    ...child,
                    key: index // Assign a unique key based on the index
                }))
            };
        } else {
            return {
                labelEn: "Name",
                labelAr: "",
                placeholder: "Name",
                required: true,
                options: [],
                childrenFields: []
            };
        }
    });


    // When updating nameData state based on modalData
    useEffect(() => {
        if (isOpen && modalData?.fieldData?.type === "Name" && modalData.fieldData.childrenFields) {
            const updatedChildren = modalData.fieldData.childrenFields.map((child: any, index: number) => ({
                ...child,
                key: index // Assign a unique key based on the index
            }));

            setNameData((prevNameData: any) => ({
                ...prevNameData,
                childrenFields: updatedChildren
            }));
        }
    }, [isOpen, modalData]);




    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = event.target;
        let val: string | boolean = value;

        if (type === 'checkbox') {
            val = (event.target as HTMLInputElement).checked; // Type assertion
        }

        setFormData((prevState: any) => ({
            ...prevState,
            [name]: val
        }));
    };

    const handleCheckboxChange = (propertyName: string) => {
        setFormData((prevState: any) => ({
            ...prevState,
            [propertyName]: !prevState[propertyName]
        }));
    };


    const handleAddOption = () => {
        if (newOption.trim()) {
            setFormData((prevState: { options: any; }) => ({
                ...prevState,
                options: [...prevState.options, newOption.trim()]
            }));
            setNewOption(""); // Clear input field after adding option
        }
    };


    const handleRemoveOption = (indexToRemove: number) => {
        setFormData((prevState: { options: any[]; }) => ({
            ...prevState,
            options: prevState.options.filter((_, index) => index !== indexToRemove)
        }));
    };


    const handleInputChangeName = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
        const { name, value, type } = event.target;
        let val: string | boolean = value;

        if (type === 'checkbox') {
            val = (event.target as HTMLInputElement).checked; // Type assertion
        }

        setNameData((prevState: any) => ({
            ...prevState,
            childrenFields: prevState.childrenFields.map((child: any, i: number) => {
                if (i === index) {
                    return {
                        ...child,
                        [name]: val
                    };
                }

                return child;
            })
        }));
    };

    const handleCheckboxChangeName = (index: number) => {
        setNameData((prevState: any) => ({
            ...prevState,
            childrenFields: prevState.childrenFields.map((child: any, i: number) => {
                if (i === index) {
                    return {
                        ...child,
                        required: !child.required
                    };
                }

                return child;
            })
        }));
    };

    const [selectedOptions, setSelectedOptions] = useState<string>("");

    const { Option } = Select;

    const ItemTypes: string[] = [
        'text',
        'decisionbox',
        'checkbox',
        'radio',
        'textarea',
        'dropdown',
        // 'button',
        'file',
        'date',
        'email',
        'phone',
    ];

    const handleChange = (selected: string) => {
        setSelectedOptions(selected);
        handleOpenModal(selected);
    };

    const [childrenModal, setChildrenModal] = useState<boolean>(false);

    const closeChildren = () => {
        setEditIndex(undefined);
        setShowSelect(false);
        setChildrenModal(false);
    }

    const isCheckboxDropdownOrRadio = (type: string): type is "checkbox" | "dropdown" | "radio" => {
        return type === "checkbox" || type === "dropdown" || type === "radio";
    }


    const handleOpenModal = (type: string) => {
        setEditIndex(undefined);

        // Define default child object with common properties
        const defaultChild: { // Define the type of defaultChild object
            type: string,
            labelEn: string,
            labelAr: string,
            labelKey: string
            placeholder: string,
            required: boolean,
            hidden: boolean,
            options: any[],
        } = {
            type: type,
            labelEn: type, // Default label
            labelAr: "",
            labelKey: "",
            placeholder: "", // Default placeholder
            required: false, // Default required value
            hidden: false, // Default hidden value
            options: [], // Initialize as an empty array
        };
        // Check if the type matches "checkbox", "dropdown", or "radio" using a type guard
        if (isCheckboxDropdownOrRadio(type)) {
            defaultChild.options = ["Option 1", "Option 2", "Option 3"]
        }

        // Create child object with defaults and orderId
        const childWithDefaults = {
            ...defaultChild,
            orderId: formData.childrenFields.length, // Assign orderId as the length of childrenFeild array
        };

        // Update formData state to include the new child object
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            childrenFields: [...prevFormData.childrenFields, childWithDefaults], // Append the new child object to the array
        }));
        setChildrenModal(true);
    }

    const handleRemoveField = (indexToRemove: number) => {
        setFormData((prevFormData: any) => {
            // Filter out the item at the specified index
            const updatedChildrenFeild = prevFormData.childrenFields.filter((_: any, index: number) => index !== indexToRemove);

            // Return the updated formData with the filtered childrenFeild array
            return { ...prevFormData, childrenFields: updatedChildrenFeild };
        });
    };


    const handleEditChildren = (index: number) => {
        // setEditIndex(undefined);
        setChildrenModal(true);
        setEditIndex(index);
    }
    const [showSelect, setShowSelect] = useState<boolean>(false);

    const handleShowSelect = () => {
        setShowSelect(true);
    }

    const { TextArea } = Input;

    const [couponsType, setCouponsType] = useState<any>({
        items: []
    })

    const getCouponsType = async () => {
        try {
            const apires = await EventsApiService().sportCategoryId(`Flag=${'RegistrationLabelKey'}` as any)
            setCouponsType(apires as any)
        } catch (error) {
            message.error("")
        }
    }
    useEffect(() => {
        getCouponsType();
    }, [])

    const onSelect = (value: string, option: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            labelEn: option.label, // Use option.label for label
            labelKey: value // Use option.value for value
        }));

        if (formData?.type === "dropdown" || formData?.type === "radio" || formData?.type === "checkbox") {
            getOptionsList(value)
        }
    };


    const onChange = (data: string) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            labelEn: data,
            labelKey: data.toLowerCase().replace(/\s+/g, '_')
        }));
    };

    const [searchOption, setSearchOption] = useState([])

    const onSearch = (searchText: string) => {
        const filteredOptions = couponsType.items.filter((item: any) =>
            item.Text.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchOption(filteredOptions.map((item: any) => ({
            value: item.Value,
            label: item.Text
        })));
    };

    const handleFocus = () => {
        onSearch(formData?.labelEn)
    };


    const getOptionsList = async (val: any) => {
        const res = await EventsApiService().getDropdownlist(`Flag=${val}` as any)
        const data: CurrencyCategory = res as CurrencyCategory;
        setFormData((prevState: { options: string[] }) => ({
            ...prevState,
            options: data?.items?.map((item: { Text: string }) => item?.Text.trim())
        }));
    }


    return (

        <>
            <>
                <div className={'popup'}>
                    <div className='modal_head'>
                        <button onClick={onClose} className='close-btn'>
                            &times;
                        </button>
                        <h4>Set Fields Properties</h4>
                    </div>
                    <div className='modal_body'>
                        <div className='mb_cstmbx'>
                            {modalData?.fieldData?.type === "Name" ?
                                <div className='mf_cstm_bx'>
                                    {modalData?.fieldData?.type === "Name" && nameData.childrenFields && nameData.childrenFields.map((child: any) => (

                                        <div className='modal_bx' key={child.key} >
                                            <div className="form-group">
                                                <label htmlFor={`labelEn_${child.key}`}> Label</label>
                                                <Input className='form-control'
                                                    size="large"
                                                    type="text"
                                                    id={`labelEn_${child.key}`}
                                                    name={`labelEn`}
                                                    value={child.labelEn}
                                                    onChange={(e) => handleInputChangeName(e, child.key)}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor={`required_${child.key}`}>
                                                    <input
                                                        width="auto"
                                                        type="checkbox"
                                                        id={`required_${child.key}`}
                                                        name={`required_`}
                                                        checked={child.required}
                                                        onChange={() => handleCheckboxChangeName(child.key)}
                                                    /> Is Required </label>


                                            </div>
                                            <div className="form-group">
                                                <label htmlFor={`placeholder_${child.key}`}> Placeholder</label>
                                                <Input className='form-control'
                                                    size="large"
                                                    type="text"
                                                    id={`placeholder${child.key}`}
                                                    name={`placeholder`}
                                                    value={child.placeholder}
                                                    onChange={(e) => handleInputChangeName(e, child.key)}
                                                />
                                            </div>
                                        </div>
                                    ))}

                                    <div className='group-button'>
                                        <button className='btn' onClick={() => onSubmit((modalData?.fieldData?.type === "Name") ? { formData: nameData } : { formData })}>Save</button>
                                    </div>

                                </div>
                                :
                                (<div className='mf_cstm_bx'>
                                    <div className="form-group">
                                        <label htmlFor="label">Label</label>
                                        <AutoComplete
                                            // options={couponsType.items.map((item: any) => ({
                                            //     value: item.Value,
                                            //     label: item.Text
                                            // }))}
                                            disabled={formData?.type === "dob"}
                                            options={searchOption}
                                            value={formData.labelEn}
                                            // style={{ width: 200 }}
                                            onSelect={onSelect}
                                            onSearch={onSearch}
                                            placeholder="input here"
                                            onChange={onChange}
                                            onClick={handleFocus}


                                        />

                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="placeholder">Label Ar</label>
                                        <Input size="large" type="text" id="labelAr" className='form-control' name="labelAr" value={formData.labelAr} onChange={handleInputChange} />
                                    </div>


                                    {/* {(formData?.type !== "decisionbox" && formData?.type !== "radio" && formData?.type !== "checkbox") &&
                                        <div className="form-group">
                                            <label htmlFor="placeholder">Placeholder</label>
                                            <Input size="large" type="text" id="placeholder" className='form-control' name="placeholder" value={formData.placeholder} onChange={handleInputChange} />
                                        </div>} */}

                                    <div className="form-group">
                                        <label htmlFor="isRequired">
                                            <input
                                                type="checkbox"
                                                id="required"
                                                name="required"
                                                checked={formData.required}
                                                onChange={() => handleCheckboxChange('required')} />
                                            Is Required</label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="isRequired">
                                            <input
                                                type="checkbox"
                                                width="auto"
                                                id="hidden"
                                                name="hidden"
                                                checked={formData.hidden}
                                                onChange={() => handleCheckboxChange('hidden')} // Pass the property name
                                            />
                                            Is Hidden</label>
                                    </div>



                                    {(formData?.type === "dropdown" || formData?.type === "radio" || formData?.type === "checkbox") &&

                                        <div className="form-group add_drpdwn_o">
                                            <label htmlFor="newOption">{modalData?.fieldData?.type === "dropdown" ? "Add Dropdown Option" : modalData?.fieldData?.type === "checkbox" ? "Add Checkbox Option" : "Add Radio Option"}</label>
                                            <div className='mlty_bx'>
                                                <Input
                                                    type="text"
                                                    id="newOption"
                                                    value={newOption}
                                                    onChange={(e) => setNewOption(e.target.value)}
                                                    onPressEnter={handleAddOption}
                                                    className='form-control'
                                                />
                                                <Button onClick={handleAddOption}>Add Option</Button>
                                            </div>
                                            <div className='option'>
                                                {formData?.
                                                    options
                                                    ?.map((item: string, i: number) => (
                                                        <div className='optn_cell' key={i}>{item} <CloseCircleOutlined className='option-close-icon' onClick={() => handleRemoveOption(i)} /></div>
                                                    ))}

                                            </div>

                                        </div>
                                    }
                                    {(modalData?.fieldData?.type === "dropdown" || modalData?.fieldData?.type === "radio") &&
                                        <>

                                            {formData?.childrenFields?.map((item: any, index: number) => (
                                                <div className='cstm_form_group' key={index} onClick={() => handleEditChildren(index)}>
                                                    <label>{item.labelEn}</label>
                                                    <div className='mltyckbx'>
                                                        {item.type === "text" && <Input placeholder={item.placeholder} />}
                                                        {item.type === "decisionbox" && <Checkbox >{item.labelEn}</Checkbox>}
                                                        {item.type === "checkbox" && <Checkbox.Group options={item?.options} />}
                                                        {item.type === "radio" &&
                                                            <Radio.Group>
                                                                {item.options.map((option: string, opIndex: number) => ( // Assuming options is an array of strings
                                                                    <Radio key={opIndex} value={option}>{option}</Radio>
                                                                ))}
                                                            </Radio.Group>
                                                        }
                                                        {item.type === "textarea" && <TextArea size="large" rows={3} maxLength={6} placeholder={item?.placeholder} />}
                                                        {item.type === "dropdown" &&
                                                            <select className='form-control'>
                                                                <option>select</option>
                                                            </select>
                                                        }
                                                        {item.type === "button" && <Button icon={<UploadOutlined />}>{item.labelEn}</Button>}
                                                        {item.type === "file" &&
                                                            <Card style={{ width: '100%' }}>
                                                                <Upload>
                                                                    <Button icon={<UploadOutlined />}>{item.labelEn}</Button>
                                                                </Upload>
                                                            </Card>}
                                                        {item.type === "date" &&
                                                            <Input size="large" placeholder={item?.placeholder} prefix={<CalendarOutlined />} />}
                                                        {item.type === "email" && <Input type="email" />}
                                                        {item.type === "phone" && <Input type="number" />}
                                                        <CloseCircleOutlined twoToneColor="#dc143c" className="close-icon" onClick={() => handleRemoveField(index)} />
                                                    </div>
                                                </div>
                                            ))}


                                            <div className='cld_grp_tbn'>
                                                <button className='btn' type='button' onClick={handleShowSelect}>Add Children&apos;s Field</button>
                                            </div>

                                            <div className='form-group'>
                                                {showSelect && <Select
                                                    // mode="multiple"
                                                    className='form-control '
                                                    style={{ width: "100%" }}
                                                    placeholder="Select options"
                                                    onChange={handleChange}
                                                >
                                                    {ItemTypes.map(option => (
                                                        <Option key={option} value={option}>
                                                            {option}
                                                        </Option>
                                                    ))}
                                                </Select>}
                                            </div>

                                        </>
                                    }
                                    <div className='group-button'>
                                        <Button loading={modalLoad} className='btn' onClick={() => onSubmit((modalData?.fieldData?.type === "Name") ? { formData: nameData } : { formData })}>Save</Button>

                                    </div>

                                </div>)

                            }

                        </div>

                    </div>
                </div>
            </>
            <div className={`cstmrightmdl child_modal ${childrenModal && "show"}`}>
                {childrenModal &&
                    <ChildrenFeildsModal formData={formData} setFormData={setFormData} closeChildrenModal={closeChildren} childrenModal={childrenModal} selectedOptions={selectedOptions} editIndex={editIndex} setEditIndex={setEditIndex} />
                }
            </div>

        </>
    )
};

