import { apiClient, ApiClientConfig, PaginatedRequestParams, PaginatedResponse } from "api";
import { axiosInstance } from "api/axios-instance";
import { CONFIG } from "config";
import { UpdateShowHideDTO } from "features/events";
export type EventsResponse = PaginatedResponse<Event>;

export type CraeteTitleCmsrDTO = {
  id?: string,
  title: string,
  is_active: boolean,
}
export type EventsParams = {
  is_active?: boolean;
  field_name?: string;
  facility_id?: string;
} & PaginatedRequestParams;
const replaceSubstring = (
  input: string,
  target: string,
  replacement: string,
): string => {
  const startIndex = input.indexOf(target);
  const endIndex = startIndex + target.length;
  const replacedStr =
    input.slice(0, startIndex) + replacement + input.slice(endIndex);

  return replacedStr;
};

export const ShopApiService = () => {

  const createCategory = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/shopcategory`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createDelivery = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/deliverycharges`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getCategory = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/shopcategory`,
      params,
    });

    return response.data;
  };

  const getItemList = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/shopitems`,
      params,
    });

    return response.data;
  };


  const createSize = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/shopitemssize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getItemColor = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/colors`,
      params,
    });

    return response.data;
  };

  const getItemSize = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/size`,
      params,
    });

    return response.data;
  };

  const createItemImage = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );
    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/masters/shopitemimages`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getItemShopSize = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/masters/shopitemssize?${id}`,
    });

    return response.data;
  };
  const updateParticipant = async (payload: UpdateShowHideDTO) => {
    const response = await apiClient({
      endpoint: `/participant/updateformsvalue`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getItemShopImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/masters/shopitemimages?${id}`,
    });

    return response.data;
  };

  const updateStatus = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/events/updatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createItem = async (payload: CraeteTitleCmsrDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/shopitems`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const UpdateItem = async (
    payload: CraeteTitleCmsrDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/masters/shopitems`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };

  const UpdateCategory = async (
    payload: CraeteTitleCmsrDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/masters/shopcategory`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };

  const UpdateDelivery = async (
    payload: CraeteTitleCmsrDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/masters/deliverycharges`,
      method: 'POST',
      data: {
        id,
        ...data, // Include other data from the payload
      },
    });

    return response.data;
  };

  // const getItem = async (
  //   params: EventsParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<EventsResponse>({
  //     ...callConfig,
  //     endpoint: `/admin/masters/shopcategory`,
  //     params,
  //   });

  //   return response.data;
  // };

  const getDelivery = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/deliverycharges`,
      params,
    });

    return response.data;
  };

  const getCountry = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/countries`,
      params,
    });

    return response.data;
  };

  const getitemdetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/shopitems/${id}`,
    });

    return response.data;
  };

  const getArea = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/area`,
      params,
    });

    return response.data;
  };

  const getMasterCoupons = async (
    flag: string,
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/coupons?Flag=${flag}`,
      params,
    });

    return response.data;
  };


  const getDropdownList = async (Flag: string) => {
    const response = await apiClient({
      endpoint: `/admin/dropdownlist?Flag=${Flag}`
    })

    return response.data
  }




  const uploadBulkExcelCoupon = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/bulkuploadcoupon/importcouponexcel`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  return {
    createCategory,
    createItem,
    getCategory,
    UpdateCategory,
    // getItem,
    getDelivery,
    getCountry,
    UpdateItem,
    createItemImage,
    getItemSize,
    updateStatus,
    getitemdetails,
    getArea,
    getItemColor,
    createSize,
    updateParticipant,
    getItemShopSize,
    getItemShopImage,
    getItemList,
    createDelivery,
    UpdateDelivery,
    getMasterCoupons,
    getDropdownList,
    uploadBulkExcelCoupon
  };
};