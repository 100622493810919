import { AutoComplete, Button, Form, Input, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { handleKeyAmount } from 'features/events';
import { TransectionApiServices } from 'features/transaction_list/api/transection-api.service';
import { useCreateWallet } from 'features/transaction_list/use-cases/create-wallet';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WalletFormField, WalletFormValues } from './types';

interface props {
    onClose: () => void,
    isOpen: boolean
}

export const AddWalletModal = ({ onClose, isOpen }: props) => {
    const formName = 'add-event-form';
    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm<WalletFormValues>();
    const { t } = useTranslation();

    const { isLoading, mutate } = useCreateWallet();
    const [selectedData, setSelectedData] = useState<any>(null);
    const [options, setOptions] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const getInitialValues = {
        [WalletFormField.Id]: undefined,
        [WalletFormField.Email]: undefined,
        [WalletFormField.Name]: undefined,
        [WalletFormField.CustomerId]: undefined,
        [WalletFormField.Description]: undefined,
        [WalletFormField.Amount]: undefined,
        [WalletFormField.FirstName]: undefined,
        [WalletFormField.LastName]: undefined,
        [WalletFormField.MobileNo]: undefined,
    };

    const handleUpdate = (formData: WalletFormValues) => {
        // formData[WalletFormField.Name] = selectedData?.first_name + selectedData?.last_name;
        formData[WalletFormField.FirstName] = selectedData?.first_name;
        formData[WalletFormField.LastName] = selectedData?.last_name;
        formData[WalletFormField.MobileNo] = selectedData?.phone_number;
        mutate(formData, { onSuccess: onClose });

    };

    useEffect(() => {
        form.resetFields();
    }, [isOpen])


    const getUsers = async (email: string) => {
        setLoading(true);
        try {
            const apires: any = await TransectionApiServices().getUsers(email);
            setOptions(apires.items.map((user: { email: any; id: any; }) => ({
                label: user.email,
                value: user.id,
                user,
            })));
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const debouncedGetUsers = useCallback(_.debounce(getUsers, 300), []);

    const handleSearch = (value: string) => {
        if (value && value.length > 2) {
            debouncedGetUsers(value);
        } else {
            setOptions([]);
        }
    };

    const handleSelect = (value: any, option: { user: any; }) => {
        const selectedUser = option.user;
        setSelectedData(selectedUser);
        form.setFieldsValue({
            [WalletFormField.Email]: selectedUser.email,
            [WalletFormField.CustomerId]: selectedUser.userid,
            // [WalletFormField.Name]: selectedUser.first_name,
            // [WalletFormField.MobileNo]: selectedUser.phone_number,
        });
    };

    const handleBlur = () => {
        const emailValue = form.getFieldValue(WalletFormField.Email);
        const matchingOption = options.find((option: { label: any; }) => option.label === emailValue);

        if (!matchingOption) {
            form.setFieldsValue({ [WalletFormField.Email]: '' });
        }
    };

    useEffect(() => {
        getUsers("")
    }, [])

    const renderOptions = () => {
        if (loading) {
            return [{
                label: <Spin />,
                value: 'loading',
                disabled: true,
            }];
        }

        return options;
    };

    return (

        <>
            <div className='popup'>
                <div className='modal_head'>
                    <button onClick={onClose} className='close-btn'>
                        &times;
                    </button>
                    <h4>Add Wallet</h4>
                </div>
                <div className='modal_body'>
                    <Form<WalletFormValues>
                        id={formName}
                        name={formName}
                        form={form}
                        layout='vertical'
                        initialValues={getInitialValues}
                        onFinish={handleUpdate}
                        // requiredMark={'optional'}
                        onFinishFailed={() => {
                            setValidateTrigger(['onChange']);
                        }}
                        validateTrigger={validateTrigger}
                        disabled={isLoading}
                    >
                        <FormSectionsSeparator title={""}>

                            <Form.Item<WalletFormField>
                                label={"Email"}
                                name={WalletFormField.Email}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <AutoComplete
                                    options={renderOptions()}
                                    placeholder={"Enter Email"}
                                    onSearch={handleSearch}
                                    onSelect={handleSelect}
                                    onBlur={handleBlur}
                                />
                            </Form.Item>

                            {/* <Form.Item<WalletFormField>
                                label={"Email"}
                                name={WalletFormField.Email}
                                rules={[
                                    {
                                        required: true,
                                        // whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}

                            >

                                <Select
                                    options={users?.items.map((res: any) => ({
                                        label: res.email, value: res.UserId, fullname: res.fullname, mobile_no: res.mobile_no, all: res
                                    }))}
                                    placeholder={"Enter Email"}
                                    onSearch={getUsers}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value: string, option: any) => {
                                        getUsers(option?.label);
                                        form.setFieldsValue({
                                            [WalletFormField.Email]: option?.label,
                                            [WalletFormField.CustomerId]: value,
                                            [WalletFormField.Name]: option?.fullname,
                                            // [WalletFormField.FirstName]: option?.first_name,
                                            // [WalletFormField.LastName]: option?.last_name,
                                            [WalletFormField.MobileNo]: option?.mobile_no // Use fullname here
                                        });
                                        setSelectedData(option.all)

                                    }}
                                />
                            </Form.Item> */}

                            <Form.Item<WalletFormField>
                                label={"Customer Id"}
                                name={WalletFormField.CustomerId}
                                rules={[
                                    {
                                        required: true,
                                        // whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },

                                ]}
                            >
                                <Input
                                    placeholder={"Enter Customer Id"}
                                    readOnly
                                />
                            </Form.Item>
                            <Form.Item<WalletFormField>
                                label={"Amount"}
                                name={WalletFormField.Amount}
                                rules={[
                                    {
                                        required: true,
                                        // whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                    {
                                        validator(__, value) {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            const stringValue = String(value);
                                            const parsedValue = stringValue.match(/^\d*\.?\d{0,3}$/);
                                            if (parsedValue) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error('Please enter a valid numeric value with at most three decimal places'));
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={"Enter Amount 0.000"}
                                    onKeyDown={handleKeyAmount}

                                />
                            </Form.Item>
                            <Form.Item<WalletFormField>
                                label={"Description"}
                                name={WalletFormField.Description}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <TextArea
                                    placeholder={"Enter Description"}
                                />
                            </Form.Item>
                        </FormSectionsSeparator>

                        <div className='button-group'>
                            <Button loading={isLoading} className='btn' htmlType='submit'>Save</Button>
                        </div>
                    </Form>

                </div>
            </div>
        </>
    );
};
