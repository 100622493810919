import { PaginatedRequestParams } from 'api';

export enum InvoiceFilterFormField {
    InvoiceId = "invoice_number",
    Name = 'name',
    MobileNo = 'MobileNumber',
    Email = 'email',
    paymentMethod = "paymentMethod",
    TransactionStatus = 'tansactionStatus',
    BookingDate = 'bookingDate',
    TransactionId = 'tranactionId',
    RefrenceNumber = 'RefrenceNumber',
    AuthId = 'AuthId',
    Currency = "currency",
    UserType = 'userType',
    Year = "year",
    EventItem = 'event_item',
    // PayType = 'pay_type',
    // Date = 'date',
}

export type InvoiceFilterFormValues = {
    [InvoiceFilterFormField.InvoiceId]: string;
    [InvoiceFilterFormField.Name]: string;
    [InvoiceFilterFormField.MobileNo]: string;
    [InvoiceFilterFormField.Email]: string;
    [InvoiceFilterFormField.paymentMethod]: string;
    [InvoiceFilterFormField.TransactionStatus]: string;
    [InvoiceFilterFormField.BookingDate]: any;
    [InvoiceFilterFormField.TransactionId]: string;
    [InvoiceFilterFormField.AuthId]: string;
    [InvoiceFilterFormField.RefrenceNumber]: string;
    [InvoiceFilterFormField.UserType]: string;
    [InvoiceFilterFormField.Year]: string;
    [InvoiceFilterFormField.EventItem]: string;
};

export type InvoiceFilterFormValuesPaginated = InvoiceFilterFormValues &
    PaginatedRequestParams;