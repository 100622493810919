import { CloseCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Input, message } from 'antd';
import { EventsApiService } from 'features/events/api/events-api.service';
import { useEffect, useState } from 'react';

import { CurrencyCategory } from '../modals/types';

interface props {
    closeChildrenModal: () => void;
    // onSubmit: (data: any) => void;
    childrenModal: boolean;
    selectedOptions: string;
    formData: any;
    // handleSaveChild: (labelEn: any, placeholder: any, isRequired: any, hidden: any) => void,
    setFormData: any;
    editIndex: number | undefined;
    setEditIndex: any | undefined;
}
const ChildrenFeildsModal = ({
    formData,
    setFormData,
    closeChildrenModal,
    editIndex,
}: props) => {

    const [labelEn, setLabelEn] = useState('');
    const [labelAr, setLabelAr] = useState('');
    const [labelkey, setLabelkey] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [isRequired, setIsRequired] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [newOption, setNewOption] = useState('');
    const [options, setOptions] = useState<any[]>([]);
    const [updateData, setUpdateData] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (editIndex !== undefined) {
            setUpdateData(formData?.childrenFields[editIndex]);
            // childIndexToUpdate = editIndex;
        } else {
            setUpdateData(formData?.childrenFields[formData.childrenFields.length - 1]);
            // childIndexToUpdate = formData.childrenFeild.length - 1;
        }

        if (updateData) {
            // console.log(updateData, "effect updateData");
            setLabelEn(updateData?.labelEn);
            setLabelAr(updateData?.labelAr)
            setPlaceholder(updateData?.placeholder);
            setIsRequired(updateData?.required);
            setHidden(updateData?.hidden);
            setOptions(prevOptions => updateData?.options || prevOptions);
        }
    }, [editIndex, formData.childrenFields])

    useEffect(() => {
        if (updateData) {
            // console.log(updateData, "effect updateData");
            setLabelEn(updateData?.labelEn);
            setLabelAr(updateData?.labelAr)
            setPlaceholder(updateData?.placeholder);
            setIsRequired(updateData?.required);
            setHidden(updateData?.hidden);
            setOptions(updateData?.options || []); // Use updateData directly here
        }
    }, [updateData]);


    // const handlePlaceholderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value;
    //     setPlaceholder(value);
    // };

    const handlelLabelArChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLabelAr(value);
    };

    const handleIsRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setIsRequired(value);
    };

    const handleIsHiddenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setHidden(value);
    };

    const handleSave = () => {
        setLoading(true);
        // Assuming you have the index of the child object you want to update
        // const childIndexToUpdate = editIndex ? editIndex : formData.childrenFeild.length - 1;
        const childIndexToUpdate =
            editIndex !== undefined ? editIndex : formData.childrenFields.length - 1;
        // console.log(childIndexToUpdate, 'childIndexToUpdate');
        setFormData((prevFormData: any) => {
            const updatedFormData = { ...prevFormData };

            const childToUpdate = updatedFormData.childrenFields[childIndexToUpdate];

            // Update the desired properties of the child object
            childToUpdate.labelKey = labelkey;
            childToUpdate.hidden = hidden;
            childToUpdate.labelEn = labelEn; // Update labelEn with the current state value
            childToUpdate.placeholder = placeholder; // Update placeholder with the current state value
            childToUpdate.required = isRequired; // Update required with the current state value
            childToUpdate.options = options;
            childToUpdate.labelAr = labelAr;

            // Update the childrenFeild array with the modified child object
            updatedFormData.childrenFields[childIndexToUpdate] = childToUpdate;

            // Return the updated formData
            return updatedFormData;
        });

        setTimeout(() => {
            closeChildrenModal();
            setLoading(false);
        }, 700)
    };

    const handleAddOption = () => {
        if (newOption.trim()) {
            // console.log('prevOptions:', options); // Log the value of prevOptions
            setOptions((prevOptions: any[]) => [...prevOptions, newOption.trim()]);
            setNewOption(''); // Clear input field after adding option
        }
    };

    const handleRemoveOption = (indexToRemove: number) => {
        setOptions((prevOptions: any[]) =>
            prevOptions.filter((_, i) => i !== indexToRemove),
        );
    };


    const [couponsType, setCouponsType] = useState<any>({
        items: []
    })

    const getCouponsType = async () => {
        try {
            const apires = await EventsApiService().sportCategoryId(`Flag=${'RegistrationLabelKey'}` as any)
            setCouponsType(apires as any)
        } catch (error) {
            message.error("error")
        }
    }
    useEffect(() => {
        getCouponsType();
    }, [])

    const onSelect = (value: string, option: any) => {
        setLabelEn(option.label);
        setLabelkey(value);
        if (updateData?.type === "dropdown" || updateData?.type === "radio" || updateData?.type === "checkbox") {
            getOptionsList(value)
        }
    };


    const onChange = (data: string) => {
        setLabelEn(data);
        setLabelkey(data.toLowerCase().replace(/\s+/g, '_'))
    };
    const [searchOption, setSearchOption] = useState([])

    const onSearch = (searchText: string) => {
        const filteredOptions = couponsType.items.filter((item: any) =>
            item.Text.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchOption(filteredOptions.map((item: any) => ({
            value: item.Value,
            label: item.Text
        })));
    };


    const getOptionsList = async (val: any) => {
        const res = await EventsApiService().getDropdownlist(`Flag=${val}` as any)
        const data: CurrencyCategory = res as CurrencyCategory;
        setOptions(data?.items?.map((item: { Text: string }) => item?.Text.trim()));
    }

    return (

        <>
            <div className={'popup'}>
                <div className='modal_head'>
                    <button onClick={closeChildrenModal} className='close-btn'>
                        &times;
                    </button>
                    <h4>Set Children Fields Properties</h4>
                </div>
                <div className='modal_body'>
                    <div className='mb_cstmbx'>
                        <div className="form-group">
                            <label htmlFor="labelEn">Label</label>
                            <AutoComplete
                                // options={couponsType.items.map((item: any) => ({
                                //     value: item.Value,
                                //     label: item.Text
                                // }))}
                                options={searchOption}
                                value={labelEn}
                                // style={{ width: 200 }}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="input here"
                                onChange={onChange}

                            />

                        </div>

                        <div className="form-group">
                            <label htmlFor="placeholder">Label Ar</label>
                            <Input className='form-control'
                                size="large"
                                type="text"
                                placeholder="Enter labelAr"
                                value={labelAr}
                                onChange={handlelLabelArChange}
                            />
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="placeholder">Placeholder</label>
                            <Input className='form-control'
                                size="large"
                                type="text"
                                placeholder="Enter Placeholder"
                                value={placeholder}
                                onChange={handlePlaceholderChange}
                            />
                        </div> */}
                        <div className="form-group is_req">
                            <Input className='form-control'
                                size="large"
                                type="checkbox"
                                id="required"
                                checked={isRequired}
                                onChange={handleIsRequiredChange}
                            />
                            <label htmlFor="isRequired" >Is Required</label>
                        </div>
                        <div className="form-group is_req">
                            <Input className='form-control'
                                size="large"
                                type="checkbox"
                                id="required"
                                checked={hidden}
                                onChange={handleIsHiddenChange}
                            />
                            <label htmlFor="isHidden">Is Hidden</label>
                        </div>


                        {(updateData?.type === "dropdown" || updateData?.type === "radio" || updateData?.type === "checkbox") &&
                            <div className="form-group add_drpdwn_o">
                                <label htmlFor="newOption">{updateData?.type === "dropdown" ? "Add Dropdown Option" : "Add Radio Option"}</label>
                                <div className='mlty_bx'>
                                    <Input className='form-control'
                                        type="text"
                                        id="newOption"
                                        value={newOption}
                                        onChange={(e) => setNewOption(e.target.value)}
                                        onPressEnter={handleAddOption}
                                    />
                                    <Button onClick={handleAddOption}>Add Option</Button>
                                </div>

                                <div className='option'>
                                    {
                                        options
                                            ?.map((item: string, i: number) => (
                                                <span className='select-option optn_cell' key={i}>{item} <CloseCircleOutlined className='option-close-icon' onClick={() => handleRemoveOption(i)} /></span>
                                            ))}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='button-group'>
                        <Button loading={loading} className='btn' onClick={handleSave}>Save</Button>
                    </div>

                </div>
            </div>
        </>

    );
};

export default ChildrenFeildsModal;
