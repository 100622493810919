import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
// import {
//   BookingType,
//   Company,
//   CompanyType,
//   Duration,
//   Facility,
//   Source,
//   Sport,
//   SportType,
//   Time,
//   Transaction,
//   User,
// } from 'types';
import { Event } from 'types/event';
import { EventsFormData } from 'types/eventsformdata';

import { MarketingCodeDTO } from '../components/event-details/event-marketing/event marketing-code/add-marketing-code-modal/types';
import { CampaignCodeDTO } from '../components/event-details/event-marketing/EventCampaign/add-campaign-code-modal/types';
import { AutoDiscountDTO } from '../components/event-details/show-hide-attributes/auto-discount/auto-discount-add-modal/types';


export type EventsResponse = PaginatedResponse<Event>;

export type EventsParams = {
  is_active?: boolean;
  field_name?: string;
  facility_id?: string;
} & PaginatedRequestParams;

export type CreateEventDTO = {
  event_master_id: string;
  event_name: string;
  event_name_arabic: string;
  categoryId: string;
  start_date: string;
  end_date: string;
  event_date: string;
  currency: string;
};

export type CreateEventFormDTO = {
  id?: any | undefined,
  event_id?: string | undefined,
  registration_json?: string | any[],
};

export type SettingFormDTO = {
  event_id: string,
  selected_event_setting: string
}
export type AtrtributeTshirtSizeDTO = {
  event_id: string,
  racekitmasterids?: any[]
}

export type UpdateEventDetailsDTO = {
  EventId: string,
  // EventDescription: string,
  EventMasterId: string,
  EventName: string,
  EventNameAR: string,
  CategoryId: string,
  EventDate: string,
  EndDate: string,
  StartDate: string,
  CurrencyId: string,
  Address: string,
  AddressLat: string,
  AddressLong: string,
  LocationMapURL: string,
  Venue: string,
  MedalPrice: number,
  TShirtPrice: number,
  DeliveryPrice: number,
  PhotoPrice: number,
  RefundInsurance: boolean,
  RefundProtectValue: number
};

export type UpdateEventDTO = {
  is_active?: boolean;
  id: string;
  rank?: number;
} & CreateEventDTO;

export type DeleteEventImageDTO = {
  id: string;
  image_url: string;
};
export type sportCategoryId = {
  Flag: string;
};

export type settingId = {
  Flag: string;
};


export type CreatePriceCategoryDTO = {
  Id?: string | undefined;
  EventId?: string | undefined;
  Category?: string;
  CategoryAr?: string;
  Description?: string;
  NoOfPersons?: string;
  RegistrationType?: string;
  GenderType?: string;
  StartNumber?: number;
  EndNumber?: number;
  Utilization?: number;
  AgeFrom?: number;
  AgeTo?: number;
  CategoryId?: number;
  DateTo?: string;
  DateFrom?: string;
  IsRefundable?: boolean;
  Refundable_charge?: number;
  Refundable_description?: string;
  IsDelivarable?: boolean;
  Delivery_charge?: number;
  Delivery_description?: string;
  MinPI?: string;
  MinPT?: string;
  MaxPI?: string;
  MaxPT?: string;
  PriceIn?: string;
  PriceTe?: string;
  Status: boolean;
  IsWaiting?: boolean;
  WaitingParticipant?: number;
  WaitingStartDate?: any;
  WaitingEndDate?: any
};


export type CreateDiscountDTO = {
  event_id: string,
  category_id: string;
  price_type: string;
  date_from: string;
  date_to: string;
  individual_price: string;
  team_price: string;
};

export type AddSponserDTO = {
  id?: string,
  event_id?: string,
  name?: string,
  contact_no?: string,
  link?: string,
  address?: string,
  email?: string,
  is_active?: boolean,
};

export type UpdateShowHideDTO = {
  EventId?: string,
  EventShowHideAttributes?: string
};

export type EventUpdateDTO = {
  id?: string,
  status?: boolean,
  flag?: string
};

export type EvetDiscriptonDTO = {
  event_id: string,
  event_description?: string,
  event_description_ar?: string,
};

export type CraeteCouponMasterDTO = {
  id?: string,
  title: string,
  coupon_type: string,
  coupon_code: string,
  value_type: string,
  value: string,
  start_date: string,
  end_date: string,
  event_id: string,
  limit: number,
  prefix: string,
  no_of_coupons: number,
  is_active: boolean,
}
export type EventResultDTO = {
  event_id: string,
  result_value: string,
  flag: string
}


export type EventParrticipantsDTO = {
  event_id: string,
  participants_url: string,
}

export const EventsApiService = () => {


  const getEvents = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/events`,
      params,
    });

    return response.data;
  };
  const getvolunteer = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventvolunteer`,
      params,
    });

    return response.data;
  };

  const getTipsadvice = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventtipsadvise`,
      params,
    });

    return response.data;
  };

  const updateEvent = async (
    payload: UpdateEventDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Event>({
      ...callConfig,
      endpoint: `/admin/events/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const createEvent = async (payload: CreateEventDTO) => {
    const response = await apiClient({
      endpoint: `/admin/events`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  // sport category Id
  const sportCategoryId = async (Flag: sportCategoryId) => {
    const response = await apiClient({
      endpoint: `/admin/dropdownlist?${Flag}`
    })

    return response.data
  }


  const getNewDropdown = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/dropdownlist`,
      params,
    });

    return response.data;
  };


  const uploadEventCoverImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/admin/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/events/${id}/cover_image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getEvent = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Event>({
      ...callConfig,
      endpoint: `/admin/events/${id}`,
    });

    return response.data;
  };

  const deleteEventCoverImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/events/${id}/cover_image`,
      method: 'DELETE',
    });

    return response.data;
  };

  const uploadEventGalleryImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/sports/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/events/${id}/images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const deleteEventGalleryImage = async (
    payload: DeleteEventImageDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, image_url } = payload;

    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/events/${id}/images/remove`,
      method: 'POST',
      data: { image_url },
    });

    return response.data;
  };

  const deleteTimeRange = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/time_ranges/${id}`,
      method: 'DELETE',
    });

    return response.data;
  };

  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    const startIndex = input.indexOf(target);
    const endIndex = startIndex + target.length;
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);

    return replacedStr;
  };

  const createRegistrationForm = async (payload: CreateEventFormDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventregistrationforms`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };





  const getEventFormData = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsFormData>({
      ...callConfig,
      endpoint: `/admin/eventregistrationforms/${id}`,
    });

    return response.data;
  };

  const getSettingsData = async (Flag: settingId) => {
    const response = await apiClient({
      endpoint: `/admin/eventsettings/geteventsettings/${Flag}`
    })

    return response.data
  }


  const updateSettingsForm = async (payload: SettingFormDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventsettings/eventsettings`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateEventDetails = async (payload: UpdateEventDetailsDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventdetails/updateeventdetails`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getEventDetailsData = async (
    id: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventdetails/${id}`,
    });

    return response.data;
  };

  const getEventDiscriptionData = async (Flag: settingId) => {
    const response = await apiClient({
      endpoint: `/admin/eventdetails/${Flag}`
    })

    return response.data
  }

  const getDiscriptionDropdown = async (Flag: sportCategoryId) => {
    const response = await apiClient({
      endpoint: `/admin/dropdownlist/${Flag}`
    })

    return response.data
  }

  const createPriceCategory = async (payload: CreatePriceCategoryDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventpricecategory`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const createDiscountTickets = async (payload: CreateDiscountDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventpricecategory/categorydiscount`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getPriceCategory = async (
    id: string,
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventpricecategory/${id}`,
      params,
    });

    return response.data;
  };

  const uploadEventDetailsImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventdetails/${id}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const deleteEventDetailsImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/eventdetails/${id}/image`,
      method: 'DELETE',
    });

    return response.data;
  };

  const createOrganizer = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/eventsorganizer`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createFaq = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/masters/faq`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };
  const createDisclaimer = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/eventdisclaimer`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getOrganizer = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventsorganizer`,
      params: restParams,
    });

    return response.data;
  };

  const getEventFaq = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    // debugger;
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/masters/faq`,
      params: restParams,
    });

    return response.data;
  };
  const getEventDisclaimer = async (
    params: { event_id: string },  // Change id to event_id
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { event_id } = params;  // Change id to event_id

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventdisclaimer`,
      params: { event_id }, // Pass only the event_id in the payload
    });

    return response.data;
  };

  const getEventOutlet = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    // debugger;
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventsoutlet`,
      params: restParams,
    });

    return response.data;
  };
  const createOutlet = async (payload: UpdateShowHideDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventsoutlet`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateParticipant = async (payload: UpdateShowHideDTO) => {
    const response = await apiClient({
      endpoint: `/admin/participant/updateformsvalue`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateStatus = async (payload: UpdateShowHideDTO) => {
    const response = await apiClient({
      endpoint: `/admin/events/updatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const uploadEventGalllary = async (id: string, typeId: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/events/${id}/gallery?type_id=${typeId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getEventGallery = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/events/gallery`,
      params: restParams,
    });

    return response.data;
  };


  const getSponser = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    // debugger;
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventssponsor`,
      params: restParams,
    });

    return response.data;
  };


  const AddSponser = async (formData: FormData) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventssponsor`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const updateShowHide = async (payload: UpdateShowHideDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventshowhideattribute`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getShowHide = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsFormData>({
      ...callConfig,
      endpoint: `/admin/eventshowhideattribute/${id}`,
    });

    return response.data;
  };

  const getShowHideAttribute = async (Flag: settingId) => {
    const response = await apiClient({
      endpoint: `/admin/eventshowhideattribute/${Flag}`
    })

    return response.data
  }


  const eventUpdateStatus = async (payload: EventUpdateDTO) => {
    const response = await apiClient({
      endpoint: `/admin/events/updatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getCouponMaster = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/coupons`,
      params,
    });

    return response.data;
  };


  const createCouponMaster = async (payload: CraeteCouponMasterDTO) => {
    const response = await apiClient({
      endpoint: `/admin/masters/coupons`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createEventDiscription = async (payload: EvetDiscriptonDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventdescription`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createEventResult = async (payload: EventResultDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventresultcode`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };



  const createResultPDF = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventsresultpdf`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const getAttributeTshirtSize = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventattribute/eventtshirtsize`,
      params,
    });

    return response.data;
  };

  const getAttributeRacekit = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventattribute/eventsracekit`,
      params,
    });

    return response.data;
  };

  const getAttributeItemList = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventattribute/eventshopitems`,
      params,
    });

    return response.data;
  };


  const updateAttributeTshirtSize = async (payload: AtrtributeTshirtSizeDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventattribute/eventtshirtsize`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const updateAttributeRacekit = async (payload: AtrtributeTshirtSizeDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventattribute/eventsracekit`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const updateAttributeItemList = async (payload: AtrtributeTshirtSizeDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventattribute/eventshopitems`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getResultCode = async (id: string) => {
    const response = await apiClient({
      endpoint: `/admin/eventresultcode?event_id=${id}`
    })

    return response.data
  }

  const getParticipantsUrl = async (id: string) => {
    const response = await apiClient({
      endpoint: `/admin/eventresultcode/resultapiurl?event_id=${id}`
    })

    return response.data
  }


  const getEventResultPdf = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventsresultpdf`,
      params: restParams,
    });

    return response.data;
  };


  const faqUploadBulk = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventfaq`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const uploadGalllaryReceiptImg = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventsresultpdf/recieptimage`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getGalleryReceiptImg = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventsresultpdf/recieptimage`,
      params: restParams,
    });

    return response.data;
  };


  const createVolunteer = async (formData: FormData) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventvolunteer`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const copyEvent = async (id: any) => {
    const response = await apiClient({
      endpoint: `/admin/events/${id}/copyevent`,
      method: 'POST',
    });

    return response.data;
  };



  const createTipsAdvice = async (formData: FormData) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/eventtipsadvise`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getTrackList = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/masters/racetrack`,
      params,
    });

    return response.data;
  };

  const createTrack = async (formData: FormData) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/masters/racetrack`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getDropdownlist = async (Flag: any) => {
    const response = await apiClient({
      endpoint: `/admin/dropdownlist?${Flag}`
    })

    return response.data
  }

  const getCampignCode = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventscampaign`,
      params,
    });

    return response.data;
  };

  const createCampignCode = async (payload: CampaignCodeDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventscampaign`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const createMarketingCode = async (payload: MarketingCodeDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventscampaign/eventmarketing`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getMarketingCode = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventscampaign/eventmarketing`,
      params,
    });

    return response.data;
  };

  const getEventEmailContent = async (
    params: { event_id: string },  // Change id to event_id
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { event_id } = params;  // Change id to event_id

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventemailcontent`,
      params: { event_id }, // Pass only the event_id in the payload
    });

    return response.data;
  };

  const createEmailContent = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/eventemailcontent`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getBibAlocation = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventbiballocation`,
      params: restParams,
    });

    return response.data;
  };

  const createBibAllocation = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/eventbiballocation`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getParticipantsRequest = async (
    id: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/sendparticipantdata/participantpostdata/${id}`,
    });

    return response.data;
  };

  const createBibRequest = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/eventbiballocation/automatic`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getEventWatingList = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;

    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/eventwaiting`,
      params: restParams,
    });

    return response.data;
  };

  const getAutoDiscount = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/eventsautodiscount`,
      params,
    });

    return response.data;
  };



  const createAutoDiscount = async (payload: AutoDiscountDTO) => {
    const response = await apiClient({
      endpoint: `/admin/eventsautodiscount`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  //expo booth

  const getExpoRegistraionForm = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsFormData>({
      ...callConfig,
      endpoint: `/admin/expoboothregistrationform/${id}`,
    });

    return response.data;
  };


  const createExpoRegistrationForm = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/expoboothregistrationform`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  return {
    createEvent,
    getEvent,
    getEvents,
    getEventFaq,
    updateEvent,
    uploadEventCoverImage,
    deleteEventCoverImage,
    uploadEventGalleryImage,
    deleteEventGalleryImage,
    deleteTimeRange,
    sportCategoryId,
    createRegistrationForm,
    getEventFormData,
    getSettingsData,
    updateSettingsForm,
    updateEventDetails,
    getEventDiscriptionData,
    getEventDetailsData,

    getDiscriptionDropdown,
    createPriceCategory,
    getPriceCategory,
    uploadEventDetailsImage,
    deleteEventDetailsImage,
    createOrganizer,
    createFaq,
    getOrganizer,
    uploadEventGalllary,
    updateParticipant,
    getEventGallery,
    createOutlet,
    getvolunteer,
    getSponser,
    updateStatus,
    AddSponser,
    updateShowHide,
    getEventOutlet,
    getShowHide,
    getShowHideAttribute,
    eventUpdateStatus,
    getCouponMaster,
    createCouponMaster,
    createEventDiscription,
    createEventResult,
    createResultPDF,
    getAttributeTshirtSize,
    getAttributeItemList,
    getAttributeRacekit,
    updateAttributeTshirtSize,
    updateAttributeRacekit,
    getEventDisclaimer,
    createDisclaimer,
    updateAttributeItemList,
    createDiscountTickets,
    getResultCode,
    getParticipantsUrl,
    getEventResultPdf,
    faqUploadBulk,
    uploadGalllaryReceiptImg,
    getGalleryReceiptImg,
    createVolunteer,
    copyEvent,
    createTipsAdvice,
    getDropdownlist,
    getTipsadvice,
    getTrackList,
    createTrack,
    getCampignCode,
    createCampignCode,
    createMarketingCode,
    getMarketingCode,
    getEventEmailContent,
    createEmailContent,
    getNewDropdown,
    getBibAlocation,
    createBibAllocation,
    getParticipantsRequest,
    createBibRequest,
    getEventWatingList,
    getAutoDiscount,
    createAutoDiscount,
    getExpoRegistraionForm,
    createExpoRegistrationForm
  };
};
