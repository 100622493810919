import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ExpoBoothList } from '../component/expo-booth-table/expo-booth-list';
import { ExpoBoothTopBar } from '../component/top-bar/expo-booth-bar';
import { ParticiateFilterFormValues } from '../component/transaction-filter-form/type';
import { useGetParticipate } from '../use-cases/get-participate';
const getInitialFilterValues: any = {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    event_name: undefined,
    invoice_id: undefined,
    invoice_no: undefined
};

export const ExpoBoothView = () => {
    const { params, updateParams, resetPage } = usePaginationParams<any>({
        page: 1,
        size: 10,
        ...getInitialFilterValues,
    });
    const { data, refetch, isFetching } = useGetParticipate(params, { retry: false });
    const handleFiltersChange = (values: ParticiateFilterFormValues) => {
        updateParams(values);
        resetPage();
        refetch()
    };

    return (

        <>
            <ExpoBoothTopBar
                handleFilter={handleFiltersChange}
                getInitialFilterValues={getInitialFilterValues}
            // refetch={""}
            />
            <ExpoBoothList
                isSocialListLoading={isFetching}
                DeliveryList={data}
                tableParams={params as any}
                updateParams={updateParams}
                refetch={refetch}

            />
            <Outlet />
        </>
    );
};
